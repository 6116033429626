import React from 'react';
import {Popup} from '@partssourceinc/react-ui-core';
import 'less/OnsiteService/cardViewDetailModal.less';
import AddQuoteToCartButton from '../../../components/AddQuoteToCartButton';

export const CardType = {
    ServiceSpecialist: 'ServiceSpecialist',
    ServiceProvider: 'ServiceProvider',  
};

export default function CardViewDetailModal({
    onClose, 
    info, 
    showServiceProviderName,
    option,
    vendorFields,
    arrivalTime,
    isQuote,
    isSingleAsset,
    showAddToCart,
    cardType,
    quote,
    onAddToCart,
}) {

    const renderScore = (score, city, state) => {
        const fullStars = Math.floor(score);
        const halfStars = score - fullStars;
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= fullStars) stars[i] = 'fa fa-star star';
            else if (halfStars > 0 && i - fullStars === 1) {
                stars[i] = 'fa fa-star-half-o star';
            } else stars[i] = 'fa fa-star-o star';
        }
        return (
            <section className="stars-section">
                <span style={{marginRight: 10}}>{score}</span>
                {stars.map((x,i) => <i key={i} className={x} />)}
                {city && state && <span className="starting-location-section">
                    <img src={`/images/icn_pin.png`} />
                    <span className="location-text">{`${city}, ${state}`}</span>
                </span>}
            </section>
        );
    };

    return (
        <Popup
            className="card-view-detail-modal"
            show={true}
            disableClose={false}
            onCancel={onClose}            
            cancelText="Cancel"
            confirmText="Add to Cart"
            hideButtons={true}
        >
            {cardType === CardType.ServiceProvider && <div className="service-provider-card">
                <div className="header-row">
                    <img src="/images/icn_repair.png" alt="Service Repair" />
                    <div className="header-info">
                        <span className="service-provider">{showServiceProviderName ? info.vendorName : `Service Provider ${info.displayIndex}`}</span>
                        {info.pQMScore && <span className="pqm-score">PQM = {info.pQMScore}</span>}
                    </div>
                </div>
                {info.summary && <div className="detail-section">
                    <div className="detail-text">
                        {info.summary}
                    </div>
                </div>}
                {info.partnerAccountrOEMModality && <div className="detail-section">
                    <div className="detail-title">
                        <img src="/images/icn_specialties.png" alt="Specialties" />
                        <span>Specialties</span>
                    </div>
                    <div className="detail-text">
                        {info.partnerAccountrOEMModality.split(';').join(', ')}
                    </div>
                </div>}
                {info.partnerAccountrISOCertified && <div className="detail-section">
                    <div className="detail-title">
                        <img src="/images/icn_iso-cert.png" alt="Certifications" />
                        <span>Certifications</span>
                    </div>
                    <div className="detail-text">
                        {info.partnerAccountrISOCertified}
                    </div>
                </div>}
                {info.partnerAccountrTrainingandCertifications && <div className="detail-section">
                    <div className="detail-title">
                        <img src="/images/icn_training.png" alt="Training" />
                        <span>Training</span>
                    </div>
                    <div className="detail-text">
                        {info.partnerAccountrTrainingandCertifications}
                    </div>
                </div>}
                {info.partnerAccountrPastCustomers && <div className="detail-section">
                    <div className="detail-title">
                        <span className="letter-h">H</span>
                        <span>Customers</span>
                    </div>
                    <div className="detail-text">
                        {info.partnerAccountrPastCustomers}
                    </div>
                </div>}
                {info.workTobePerformed && <div className="detail-section">
                    <div className="detail-title">                        
                        <span>Scope of Work to be Performed</span>
                    </div>
                    <div className="detail-text">
                        {info.workTobePerformed}
                    </div>
                </div>}
                {info.resourceOtherCommentsQualification && <div className="detail-section">
                    <div className="detail-title">                        
                        <span>Additional Service Event Information</span>
                    </div>
                    <div className="detail-text">
                        {info.resourceOtherCommentsQualification}
                    </div>
                </div>}
                {info.partnerAccountrTotalRecentWorkCompletedfrm && <div className="similar-jobs">
                    <span className="job-count">{info.partnerAccountrTotalRecentWorkCompletedfrm}</span>
                    <span>Similar Jobs Performed</span>
                </div>}
            </div>}
            {cardType === CardType.ServiceSpecialist && <div className="service-specialist-card">
                <div className="header-row">
                    <img src="/images/icn_serviceman.png" alt="Service Specialist" />
                    <div className="header-info">
                        <span className="service-provider">{isQuote ? `Service Specialist ${info.displayIndex}` : info.name}</span>
                        {showServiceProviderName && <span className="service-provider-name">{info.servicePartner}</span>}
                        <span className="star-rating">
                            {renderScore(info.fSEStarRating, info.startingLocationCity, info.startingLocationState)}                       
                        </span>                  
                    </div>
                    {info.yearExperiencefrm && <div className="years-of-experience">{info.yearExperiencefrm}</div>}
                    {info.oEMModality && <div className="detail-section">
                        <div className="detail-title">
                            <img src="/images/icn_specialties.png" alt="Specialties" />
                            <span>Specialties</span>
                        </div>
                        <div className="detail-text">
                            {info.oEMModality.split(';').join(', ')}
                        </div>
                    </div>}
                    {info.iSOCertified && <div className="detail-section">
                        <div className="detail-title">
                            <img src="/images/icn_iso-cert.png" alt="Certifications" />
                            <span>Certifications</span>
                        </div>
                        <div className="detail-text">
                            {info.iSOCertified}
                        </div>
                    </div>}
                    {info.trainingCertifications && <div className="detail-section">
                        <div className="detail-title">
                            <img src="/images/icn_training.png" alt="Training" />
                            <span>Training</span>
                        </div>
                        <div className="detail-text">
                            {info.trainingCertifications}
                        </div>
                    </div>}
                    {info.peerHospitals && <div className="peer-hospitals">
                        <span className="peer-title">
                            <span className="letter-h">H</span>
                            Customers
                        </span>
                        <span className="peer-list">{info.peerHospitals}</span>
                    </div>}
                    {info.workTobePerformed && <div className="detail-section">
                        <div className="detail-title">                        
                            <span>Scope of Work to be Performed</span>
                        </div>
                        <div className="detail-text">
                            {info.workTobePerformed}
                        </div>
                    </div>}
                    {info.resourceOtherCommentsQualification && <div className="detail-section">
                        <div className="detail-title">                        
                            <span>Additional Service Event Information</span>
                        </div>
                        <div className="detail-text">
                            {info.resourceOtherCommentsQualification}
                        </div>
                    </div>}
                    {info.similarJobsPerformed && <div className="similar-jobs">
                        <span className="job-count">{info.similarJobsPerformed}</span>
                        <span>Similar Jobs Performed</span>
                    </div>}
                </div>
            </div>
            }

            {showAddToCart && <div className="card-view-footer">
                <span className="cancel-button" onClick={onClose}>Cancel</span>
                <AddQuoteToCartButton option={option} quote={quote} vendorFields={vendorFields} onAddToCart={onAddToCart} />
            </div>}
        </Popup>
    );
}

import axios from 'axios';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';
import Attachments from 'components/InfoCenter/Attachments';
import EventLogs from 'components/InfoCenter/EventLogs';
import Notes from 'components/InfoCenter/Notes';
import QuoteNotes from 'components/InfoCenter/QuoteNotes';
import OrderDetails from 'components/InfoCenter/OrderDetails';
import Photos from 'components/InfoCenter/Photos';
import {Popup} from '@partssourceinc/react-ui-core';

import 'less/InfoCenter.less';

@connect(state => ({user: state.user}))
export default class InfoCenterMain extends Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onOrderDetailChange: PropTypes.func.isRequired,
        lineItemId: PropTypes.number.isRequired,
        orderId: PropTypes.number.isRequired,
        requesterId: PropTypes.number.isRequired,
        activeTab: PropTypes.string.isRequired,
        disableClose: PropTypes.bool.isRequired,
        reqFields: PropTypes.array.isRequired,
        optFields: PropTypes.array.isRequired,
        fields: PropTypes.array.isRequired,
        submitDisabled: PropTypes.func.isRequired,
        priority: PropTypes.any.isRequired, 
        onCheckBoxUpdate: PropTypes.func.isRequired,
        vendorResearchId: PropTypes.number,
        showOnlyConditionNotes: PropTypes.bool,
        onQuoteNotesSaved: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.renderMainInfoScreen = this.renderMainInfoScreen.bind(this);
        this.conditionalRender = this.conditionalRender.bind(this);
        this.renderAssetInfoTab = this.renderAssetInfoTab.bind(this);
        this.backToInfoCenter = this.backToInfoCenter.bind(this);

        this.state = {
            activeTab: '',
            assetDetails: {},
            eventLogs: [],
            photos: [],
            loadingNotes: false
        };
    }

    componentDidMount() {
        this.setState({loadingNotes: true})
        const {lineItemId, activeTab} = this.props;

        if (activeTab !== '') {
            this.setState({activeTab: activeTab});
        }
        axios
            .get(`ShoppingService/api/v1/orders/asset/${lineItemId}`)
            .then((resp) => this.setState({assetDetails: resp.data}))
            .finally(() => this.setState({loadingNotes: false}));
        axios
            .get(`ShoppingService/api/v1/lineItems/${lineItemId}/events`)
            .then(resp => this.setState({eventLogs: resp.data.history}));

        axios
            .get(`ShoppingService/api/v1/lineItems/${lineItemId}/photos`)
            .then(resp => this.setState({photos: resp.data}));
    }

    closePopup() {
        const {disableClose, onCancel} = this.props;
        
        if (disableClose) return;
        onCancel();
    }

    backToInfoCenter(e, assetDetails, photos) {
        const {activeTab} = this.state;
        e.preventDefault();

        if (activeTab === 'Photos') {
            this.setState({photos: photos, activeTab: 'Main'})
        } else if (assetDetails) {
            this.setState({assetDetails: assetDetails, activeTab: 'Main'})
        } else {
            this.setState({activeTab: 'Main'})
        }
    }

    requesterChange(requester) {
        const {onOrderDetailChange} = this.props;
        onOrderDetailChange(requester);
    }

    getFieldValues(values, formType, requester, isUrgency) {
        const {onSubmit} = this.props;
        onSubmit(values, formType, requester, isUrgency);
    }

    renderMainInfoScreen() {
        let {reqFields, optFields, fields} = this.props;
        let reqField = reqFields.filter(f => f.value) || [];
        let optField = optFields.filter(f => f.value) || [];
        const {eventLogs, photos, assetDetails, activeTab} = this.state;
        if (!fields)
            fields = [...reqField, ...optField];
        const allowAssetInfoEdit = assetDetails.orderTypeId !== 17;

        return (<div className={'col-xs-12'}>
            <div className="title">Info Center</div>
            <div className="tabContainer">
                <span className="tabTitle">Asset Information </span>
                {allowAssetInfoEdit && <span>
                    <span className="viewEditLink" onClick={() => this.setState({activeTab: 'Asset'})}>
                            View/Edit
                    </span>
                    <span className="counterSink">⌵</span>
                </span>
                }
                {fields && fields.length > 0 && <div className="tabContent">
                    {fields.filter(x => x.value).map((f, i) => (<p key={i}>
                        <span>{`${f.prompt}:`}</span>
                        <span className="tabContentValues"> {f.value}</span>
                    </p>))}
                </div>}
            </div>
            <div className="tabContainer">
                <span className="tabTitle">Attachments </span>
                <span className="viewEditLink" onClick={() => this.setState({activeTab: 'Attachments'})}>
                    View/Edit
                </span>
                <span className="counterSink">⌵</span>
                <div className="tabContentShort">
                    ({assetDetails.attachments ? assetDetails.attachments.length : 0})
                    <span> Files</span>
                </div>
            </div>
            <div className="tabContainer">
                <span className="tabTitle">Event Log </span>
                <span className="viewEditLink" onClick={() => this.setState({activeTab: 'EventLog'})}>
                    View/Edit
                </span>
                <span className="counterSink">⌵</span>
                <div className="tabContentShort">
                    ({eventLogs ? eventLogs.length : 0})<span> Logs</span>
                </div>
            </div>
            <div className="tabContainer">
                <span className="tabTitle">Order Notes</span>
                <span className="viewEditLink"
                    onClick={() => this.setState({activeTab: 'Notes'})}> View/Edit </span>
                <span className="counterSink">⌵</span>
                <div className="tabContentShort">
                    ({assetDetails.notes ? assetDetails.notes.length : 0})
                    <span> Notes</span>
                </div>
            </div>
            <div className="tabContainer">
                <span className="tabTitle">Order Details</span>
                <span className="viewEditLink" onClick={() => this.setState({activeTab: 'OrderDetails'})}> View/Edit </span>
                <span className="counterSink">⌵</span>
            </div>
            <div className="tabContainer">
                <span className="tabTitle">Photos</span>
                <span className="viewEditLink" onClick={() => this.setState({activeTab: 'Photos'})}> View/Edit </span>
                <span className="counterSink">⌵</span>
                <div className="tabContentShort">
                    ({photos ? photos.length : 0})
                    <span> Photos</span>
                </div>
            </div>
        </div>);
    }

    renderAssetInfoTab() {
        const {fields, requesterId, submitDisabled, priority, onCheckBoxUpdate, lineItemId} = this.props;
        const {assetDetails} = this.state;

        return (
            <AssetInformationForm
                fields={fields.filter(requiredField => requiredField.lineItemId === lineItemId)}
                onSubmit={::this.getFieldValues}
                formType={AssetFormType.Both}
                showRequester={true}
                displayInline={true}
                backToInfoCenter={this.backToInfoCenter}
                requesterFacilityId={assetDetails.facilityId}
                requesterId={requesterId}
                submitDisabled = {submitDisabled}
                priority = {priority}
                onCheckBoxUpdate = {onCheckBoxUpdate}
            />
        );
    }

    renderAttachmentsTab() {
        const {assetDetails} = this.state;
        return <Attachments backToInfoCenter={this.backToInfoCenter} assetDetails={assetDetails} />;
    }

    renderEventLogTab() {
        const {eventLogs} = this.state;
        const {lineItemId} = this.props;
        return <EventLogs backToInfoCenter={this.backToInfoCenter} lineItemId={lineItemId} eventLogs={eventLogs} />;
    }

    renderNotesTab() {
        const {assetDetails} = this.state;
        return <Notes backToInfoCenter={this.backToInfoCenter} assetDetails={assetDetails} />;
    }

    renderAllNotesTab() {
        const {assetDetails, loadingNotes} = this.state;
        const {conditionNotes, showOnlyConditionNotes} = this.props;
        return <Notes backToInfoCenter={this.backToInfoCenter} assetDetails={assetDetails} isAllNotes={false} conditionNotes={conditionNotes} loadingNotes={loadingNotes} showOnlyConditionNotes={showOnlyConditionNotes} />;
    }

    renderQuoteNotesTab() {
        const {assetDetails} = this.state;
        const {vendorResearchId, onQuoteNotesSaved} = this.props;

        return <QuoteNotes backToInfoCenter={this.backToInfoCenter} onQuoteNotesSaved={onQuoteNotesSaved} assetDetails={assetDetails} vendorResearchId={vendorResearchId} />;
    }

    renderOrderDetailsTab() {
        const {assetDetails} = this.state;
        const {orderId, lineItemId, requesterId} = this.props;
        return (<OrderDetails assetDetails={assetDetails} orderId={orderId} backToInfoCenter={this.backToInfoCenter} 
            lineItemId={lineItemId} requesterId={requesterId} onSubmit={::this.requesterChange} />);
    }

    renderPhotosTab() {
        const {assetDetails, photos} = this.state;
        const {lineItemId} = this.props;
        return <Photos assetDetails={assetDetails} backToInfoCenter={this.backToInfoCenter} photos={photos} lineItemId ={lineItemId} />;
    }

    conditionalRender() {
        const {activeTab} = this.state;
        switch (activeTab) {
            case 'Asset':
                return this.renderAssetInfoTab();
            case 'Attachments':
                return this.renderAttachmentsTab();
            case 'EventLog':
                return this.renderEventLogTab();
            case 'Notes':
                return this.renderNotesTab();
            case 'AllNotes':
                return this.renderAllNotesTab();
            case 'QuoteNotes':
                return this.renderQuoteNotesTab();
            case 'OrderDetails':
                return this.renderOrderDetailsTab();
            case 'Photos':
                return this.renderPhotosTab();
            case 'Main':
                return this.renderMainInfoScreen();
        }
    }

    render() {
        return (
            <Popup
                className="InfoCenter"
                show={true}
                onCancel={::this.closePopup}
                hideButtons={true}
            >
                {this.conditionalRender()}
            </Popup>
        );
    }
}

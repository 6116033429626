import axios from 'axios';
import moment from 'moment';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {TextField, Button} from '@partssourceinc/react-ui-core';

@connect(state => ({user: state.user}))
export default class QuoteNotes extends Component {
    static propTypes = {
        backToInfoCenter: PropTypes.func.isRequired,
        assetDetails: PropTypes.object.isRequired,
        vendorResearchId: PropTypes.number.isRequired,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            note: '',
            warningMessage: null,
            assetDetails: props.assetDetails,
            loading: false,
        };
    }

    componentDidUpdate(previousProps) {
        if (previousProps.assetDetails != this.props.assetDetails) {
            this.setState({assetDetails: this.props.assetDetails});
        }
    }

    saveNote() {
        const {note} = this.state;
        const {assetDetails: {lineItemId}, vendorResearchId, onQuoteNotesSaved} = this.props;

        this.setState({loading: true});

        let request = {
            lineItemId: lineItemId,
            note: note,
            vendorResearchId: vendorResearchId,
        };

        axios
            .post(`ShoppingService/api/v1/lineItems/saveQuoteNote`, request)
            .then((resp) => {
                this.setState({assetDetails: resp.data, loading: false});

                if (resp.data.notes && onQuoteNotesSaved) {
                    onQuoteNotesSaved(resp.data.notes);
                }
            });

        this.setState({note: '', warningMessage: null});
    }

    updateNote(e) {
        let content = e.target.value;

        if (/^[\s]+$/i.test(content)) {
            this.setState({
                warningMessage: ' ',
            });
            return;
        } else {
            this.setState({
                warningMessage: null,
            });
        }
        
        if (content.length >= 120) {
            let trimmedContent = content.substring(0, 120);
            this.setState({note: trimmedContent, warningMessage: 'Please limit your note to 120 characters.'});
        } else {
            this.setState({note: content, warningMessage: null});
        }
    }   
    
    renderNotes(note) {
        return (
            <div className="note">
                <p className="noteTitle">
                    <span className="noteTitle">{note.createdBy}</span> -{' '}
                    <span>{moment(note.dateCreated).format('MM/DD/YYYY hh:mm A')}</span>
                </p>
                <p className="noteContent">{note.text}</p>
            </div>
        );
    }

    render() {
        const {backToInfoCenter, vendorResearchId} = this.props;
        const {assetDetails, note, loading, warningMessage} = this.state;

        let conditionalNotes = [] 
        if (assetDetails.conditionalNotes && assetDetails.conditionalNotes.length > 0)
            conditionalNotes = assetDetails.conditionalNotes.filter(x => x.vendorResearchId == vendorResearchId);

        return (
            <div className="notesPage">
                <div>
                    <div className="goBack" onClick={event => backToInfoCenter(event, assetDetails)}>
                        <span className="counterSink">⌵</span>
                        <span className="backToInfoCenter">Back to Info Center</span>
                    </div>
                    <div>
                        <h1 className="title">Quote Notes</h1>
                    </div>
                </div>
                <TextField
                    id="Note"
                    multiLine={true}
                    rows={2}
                    text={note}
                    label="New Note"
                    placeholder="New Note"
                    required={true}
                    onChange={::this.updateNote}
                    limit={120}
                />
                <p style={{'position': 'absolute'}}>{warningMessage}</p>
                <Button
                    style={{'margin-top': '30px'}}
                    disabled={!note || warningMessage}
                    className="btnSave"
                    secondary={true}
                    onClick={::this.saveNote}
                    loading={loading}
                >
                    SUBMIT
                </Button>
                <div className="notesWrapper">
                    {conditionalNotes && conditionalNotes.length > 0 ? (
                        conditionalNotes.map(note => {
                            return this.renderNotes(note);
                        })
                    ) : (
                        <p className="noFile">No Quote Notes</p>
                    )}
                </div>
            </div>
        );
    }
}

import React, {useState, useCallback} from 'react';
import PageMetaData from 'components/PageMetaData';
import {ServiceType, getSubTitle} from '../helpers';
import LineQuote from 'components/LineQuote';
import SalesQuote from 'components/SalesQuote';
import {actionCreators as storeActions} from 'stores/Cart';
import _ from 'lodash'
import 'less/onSiteHeader.less';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {getFormattedPhoneNumber} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';

export default function OnSiteHeader({type, urgencyId, title, quote}) {
    const user = useSelector((state) => state.user);
    const {settings: {hidePricing, trinityRequisition,awaitingPoEnabled, dedicatedPhoneNumber}} = user;

    const history = useHistory();
    const location = useLocation();
    const {resetCheckout, saveCheckout} = storeActions;
    const dispatch = useDispatch();
    const formattedTitle = () => title.split(' by ')[0];
    const [showPrintQuote, setShowPrintQuote] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const dedicatedPhoneNumberStr = dedicatedPhoneNumber ? getFormattedPhoneNumber(dedicatedPhoneNumber) : defaultPhoneNumber;

    const lineItem = quote.detail;

    const handleOnPrintConfirmation = useCallback(() => {
        let orderLines = [{...quote.detail}]
        let o = orderLines[0];
        dispatch(resetCheckout());
        let poLines = [];
        // clone array
        let checkoutLines = JSON.parse(JSON.stringify(orderLines));

        checkoutLines.forEach(x => {
            x.fields = x.fieldValues;
            x.uomCode = x.unitOfMeasure;
            x.price = x.unitPrice;
            x.quantity = x.quantity;
            x.oemName = x.manufacturer;
            x.requestorName = x.requestor;
            x.imagePath = x.imagePath;
            poLines = x.poLines ? poLines.concat(x.poLines) : [];
        });

        let checkout = {
            items: checkoutLines,
            facility: {facilityName: o.facilityName},
            facilitySettings: {},
            shippingMethod: {
                carrierId: o.shippingMethod && o.shippingMethod.toLowerCase().indexOf('fedex') > -1 ? 1 : 2,
                shippingInsurance: o.shippingInsurance,
            },
            paymentInformation: {
                poNumber: o.poNumber,
                paymentMethod: {
                    value: o.paymentMethod,
                },
            },
            selectedShipOption: {
                serviceTypeDescription: o.shippingPriority,
                netShippingCharge: _.sumBy(poLines, 'shipping'),
            },
            shippingLocation: {
                attentionTo: o.shippingAttention,
            },
            billingAddress: o.billingAddress,
            shippingAddress: o.shippingAddress,
            totals: {
                taxes: _.sumBy(poLines, 'tax'),
                creditCardFees: _.sumBy(poLines, 'creditCardFee'),
                processingFees: _.sumBy(poLines, 'processingFee'),
                minOrderFees: _.sumBy(poLines, 'minOrderFee'),
                exchangeFees: _.sumBy(poLines, 'exchangeFee'),
                programFees: _.sumBy(poLines, 'programFee'),
                oemFees: _.sumBy(poLines, 'oemFee'),
            },
        }

        dispatch(saveCheckout(checkout));
        setShowConfirmation(true);

    });

    const OnShowTrinity = useCallback(() => {
        let orderLines = [{...quote.detail}]
        let o = orderLines[0];
      
        const fileName = `THCE_${o.orderId}.csv`;
        const csv = `/CustomerCommunicationsService/api/v1/report/TR?orderId=${o.orderId}`;
        axios.get(csv, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', 'Accept': 'text/csv'}}).then(x => {
            const data = x.data;
            if (x.status === 200) {
                const file = new Blob([data], {type: 'text/csv'});

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file);
                    return;
                }

                const fileURL = URL.createObjectURL(file);
                let link = document.createElement('a');
                link.type = 'hidden';
                link.href = fileURL;
                link.download = `${fileName}`;
                document.body.appendChild(link);
                link.click();

                setTimeout(function () {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
            }
        });

    });

    const onBackToCases = () => {
        if (location && location.state && location.state.from) {
            const prevPage = location.state.from.toLowerCase();
            const goback = prevPage.indexOf('/on-site-service') > -1 ||
                prevPage.indexOf('/quotes') > -1;
            if (goback) history.goBack();
            else history.push('/on-site-service');
        } else {
            history.push('/on-site-service');
        }
    }

    return (
        <React.Fragment>
            <header className="on-site-header">
                <section>
                    <PageMetaData
                        title="Service Details"
                        pageType="other"
                        trackAnalytics={true}
                    />
                    <span className="on-site-label chevron">My Account</span>
                    <span className="on-site-sub-label">On-Site Service</span>
                </section>
                <section className="top-row">
                    <div className="back-text" onClick= {onBackToCases}>
                        <i
                            className="fa fa-chevron-left"
                            aria-hidden="true"
                            style={{paddingRight: 10}}
                        />Back to Cases</div>
                    <div className="cancel-quote-text">For questions or to cancel this quote, call <span style={{whiteSpace: 'nowrap'}}><a href={'tel:+1' + dedicatedPhoneNumberStr}>{dedicatedPhoneNumberStr}</a></span></div>
                </section>
                <section className="title-row">
                    <div className="title-row-top">
                        <span className={`title`}>Reference #{lineItem.lineItemId}</span>
                        {!(lineItem.serviceInfo && lineItem.serviceInfo.ticketId !== 0) && <span>
                            {type === ServiceType.QUOTE || type === ServiceType.PENDING_QUOTE ? <span hidden={true} className="print-quote" onClick={() => setShowPrintQuote(true)}>Print Quote</span> : <span onClick={handleOnPrintConfirmation} hidden={true} className="print-confirmation">Print Confirmation</span>}
                        </span>}
                    </div>
                    <div className="title-row-top">
                                          
                        {(trinityRequisition && awaitingPoEnabled && type === ServiceType.ORDER) && <span hidden={true} className="print-confirmation" onClick={OnShowTrinity}> Download Requisition CSV </span>}
                    </div>
                    {urgencyId === 1 && (
                        <span className="critical-hard-down">
                            <i className="fa fa-exclamation" /> Critical Hard Down
                        </span>
                    )}
                </section>

                <section className="description-row" >
                    <div className="subtitle" >{getSubTitle(type)}</div>
                    {type === ServiceType.QUOTE && (
                        <div className="description" >
            Below you will find the Service Specialist we have identified
            through PRECISION™ Procurement, our proprietary service partner
            quality ranking module. We have also included the details of your
            request for your convenience.
                        </div>
                    )}
                </section>
            </header>
            {showPrintQuote && <LineQuote hidePricing={hidePricing} quote={quote} onClose={() => setShowPrintQuote(false)} />}
            {showConfirmation && <SalesQuote isConfirmation={true} onClose={() => setShowConfirmation(false)} />}

        </React.Fragment>
    );
}

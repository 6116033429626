import React from 'react';

export default function AssetInformation({category, oem, equipmentSerial, assetId, costCenter, model, oemId, modelId}) {
    if (!(modelId > 0) && oemId == 26799 && !category) return null;
    return (
        <section className="item">
            <h1 className="section-title">Asset Information</h1>
            {oem && oemId != 26799 && (
                <div className="row-line">
          OEM: <span className="value">{oem}</span>
                </div>
            )}
            {model && modelId > 0 && (
                <div className="row-line">
          Model Number: <span className="value">{model}</span>
                </div>
            )}
            {category && <div className="row-line">Category: <span className="value">{category}</span></div>}
        </section>
    );
}

import {BrComponent, BrPageContext} from '@bloomreach/react-sdk';
import React, {useEffect, useState, useContext} from 'react';
import PageMetaData from 'components/PageMetaData';
import OnSiteHeader from './components/OnSiteHeader';
import ServiceProviderCard from 'pages/OnSiteService/components/ServiceProviderCard';
import {useParams} from 'react-router-dom';
import {ServiceType, getType, convertArrayToObject} from './helpers';
import axios from 'axios';
import 'less/OnSiteService/onSiteServiceDetail.less';
import OtherInformation from './components/OtherInformation';
import ProgressTracker from './components/ProgressTracker';
import Carousel from '../../components/Carousel';
import WorkOrdersGrid from './components/WorkerOrdersGrid';
import ServiceSpecialistCard from './components/ServiceSpecialistCard';
import {useDispatch, useSelector} from 'react-redux'
import {actionCreators as cartActions} from 'stores/Cart'
import SalesQuote from 'components/SalesQuote';
import _ from 'lodash';
import {useHistory, useLocation} from 'react-router-dom';
import moment from 'moment';
import {getFormattedPhoneNumber} from 'utility';

export default function OnSiteServiceDetail(props) {
    const {id} = useParams();
    const page = useContext(BrPageContext);
    const [serviceItem, setServiceItem] = useState();
    const [type, setType] = useState();
    const [quote, setQuote] = useState();
    const [serviceInfo, setServiceInfo] = useState({workOrders: []});
    const [showSortByMenu, setShowSortByMenu] = useState(false);
    const [isMultiAsset, setIsMultiAsset] = useState(false);
    const [isQuote, setIsQuote] = useState(true);
    const [technicians, setTechnicians] = useState([]);
    const [serviceProviders, setServiceProviders] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState({});
    const [hasContract, setHasContract] = useState(false);

    const dispatch = useDispatch();
    const {loadUserCart, removeCartItem, resetCheckout, saveCheckout} = cartActions;
    const showServiceProviderName = useSelector(state => state.user.settings.showServiceProviderName);

    const history = useHistory();
    const location = useLocation();

    const SortTypeEnum = {
        Highest: 'Highest Rated',
        Quickest: 'Quickest Availability',
        Cost: 'Cost - Low to High',
    };

    const [sortType, setSortType] = useState(SortTypeEnum.Highest);

    const responsiveSizes = [
        {min: 0, max: 799, itemSize: 340},
        {min: 800, max: 9999, itemSize: 650},
    ]

    useEffect(() => {
        if (page.isPreview()) {
            return;
        }

        loadQuoteDetails();

        return () => {
            dispatch(resetCheckout());
        };
    }, []);

    useEffect(() => {
        setTechnicians(getSortedData(technicians));
        setServiceProviders(getSortedData(serviceProviders));
    }, [sortType]);

    const getSortedData = (data, sort) => {
        switch (sort || sortType) {
            case SortTypeEnum.Highest:
                data = data.pQMScore !== undefined ? 
                    _.orderBy(data, 
                        [item => parseFloat(item.pQMScore || 0), 'displayIndex'], 
                        ['desc', 'asc']) : 
                    _.orderBy(data, 
                        [item => parseFloat(item.fSEStarRating || 0), 'displayIndex'],
                        ['desc', 'asc']);
                break;
            case SortTypeEnum.Quickest:
                data = _.orderBy(data, 
                    [item => moment(item.arrivalTimeCommitment ? item.arrivalTimeCommitment.replace('-04:00', '') : '2099-12-30T12:00:00-00:00', 'YYYY-MM-DD-h.mm.ss').valueOf(), 'displayIndex'],['asc', 'asc']);
                break;
            case SortTypeEnum.Cost:
                data = _.orderBy(data, 
                    [item => parseFloat(item.price || 0), 'displayIndex'], ['asc', 'asc']);
                break;
        }

        return data;
    };

    const loadQuoteDetails = () => {
        axios.get(`/ShoppingService/api/v1/quotes/${id}`).then((response) => {
            const {data: {quote}} = response;
            let {detail} = quote;

            setQuote(quote);
            setIsQuote(detail.statusId === 26);

            if (detail) {                
                detail.salesForceData = convertArrayToObject(detail.serviceInfo.salesForceItems,'key');
                const isSingleAsset = detail.serviceInfo.requestType === 'SingleAsset'
                setIsMultiAsset(!isSingleAsset);
                setHasContract(detail.serviceInfo.hasContract);
                let svcProviders = [];
                detail.serviceInfo.serviceProviders.map(x => {
                    svcProviders.push(convertArrayToObject(x, 'key'));
                });
                // Use Default Sort to Keep Display Index Consistent
                svcProviders = getSortedData(svcProviders, SortTypeEnum.Highest);
                svcProviders.map((x,i) => x.displayIndex = i + 1);
                svcProviders = getSortedData(svcProviders);
                setServiceProviders(svcProviders);

                setSelectedProvider(svcProviders.find(x => x.isSelected) || {});

                let svcTechnicians = [];
                detail.serviceInfo.technicians.map(x => {
                    svcTechnicians.push(convertArrayToObject(x, 'key'));
                });
                // Use Default Sort to Keep Display Index Consistent
                svcTechnicians = getSortedData(svcTechnicians, SortTypeEnum.Highest);
                svcTechnicians.map((x, i) => {
                    let p = svcProviders.find(pp => pp.vendorResearchId === x.vendorResearchId);
                    x.pricingType = p ? p.pricingType : 'N/A';
                    x.displayIndex = i + 1;
                    x.arrivalTimeCommitment = p.arrivalTimeCommitment;
                    x.pricingComment = p.pricingComment;
                    x.workTobePerformed = p.workTobePerformed;
                    x.resourceOtherCommentsQualification = p.resourceOtherCommentsQualification;
                });
                if (detail.statusId !== 26) {
                    let slctdProvider = svcProviders.find(x => x.isSelected === 'true');
                    if (slctdProvider)
                        svcTechnicians = svcTechnicians.filter(x => x.vendorResearchId === slctdProvider.vendorResearchId);
                }
                setTechnicians(getSortedData(svcTechnicians));

                detail.vendorFields = quote.vendorFields;
                detail.options = quote.options;

                setServiceItem(detail);
                setServiceInfo(detail.serviceInfo);
                let svcType = getType(detail.statusId, detail.extStatusId);
                setType(svcType);
                setIsQuote(svcType === ServiceType.QUOTE || svcType === ServiceType.PENDING_QUOTE);
            }
        });
    };

    const onChangeSortType = (sortTypeEnum) => {
        setSortType(sortTypeEnum);
        setShowSortByMenu(false);
    };

    const onRemoveFromCart = (lineItemId) => {
        dispatch(removeCartItem(lineItemId)).then(() => {
            dispatch(loadUserCart());
            loadQuoteDetails();
        });
    }

    const onPrintConfirmation = () => {
        let orderLines = [serviceItem]
        let o = orderLines[0];
        dispatch(resetCheckout());
        let poLines = [];
        // clone array
        let checkoutLines = JSON.parse(JSON.stringify(orderLines));

        checkoutLines.forEach(x => {
            x.fields = x.fieldValues;
            x.uomCode = x.unitOfMeasure;
            x.price = x.unitPrice;
            x.oemName = x.manufacturer;
            x.requestorName = x.requestor;
            poLines = x.poLines ? poLines.concat(x.poLines) : [];
        });

        let checkout = {
            items: checkoutLines,
            facility: {facilityName: o.facilityName},
            facilitySettings: {},
            shipments: [
                {
                    lineItemIds: _.map(checkoutLines, x => x.lineItemId),
                    shippingAddress: {addressDisplay: o.shippingAddress},
                    shippingLocation: {
                        attentionTo: o.shippingAttention,
                    },
                },
            ],
            shippingMethod: {
                carrierId: o.shippingMethod && o.shippingMethod.toLowerCase().indexOf('fedex') > -1 ? 1 : 2,
                shippingInsurance: o.shippingInsurance,
            },
            paymentInformation: {
                poNumber: o.poNumber,
                paymentMethod: {
                    value: o.paymentMethod,
                },
            },
            selectedShipOption: {
                serviceTypeDescription: o.shippingPriority,
                netShippingCharge: _.sumBy(poLines, 'shipping'),
            },
            shippingLocation: {
                attentionTo: o.shippingAttention,
            },
            billingAddress: o.billingAddress,
            shippingAddress: {addressDisplay: o.shippingAddress},
            totals: {
                taxes: _.sumBy(poLines, 'tax'),
                creditCardFees: _.sumBy(poLines, 'creditCardFee'),
                processingFees: _.sumBy(poLines, 'processingFee'),
                minOrderFees: _.sumBy(poLines, 'minOrderFee'),
                exchangeFees: _.sumBy(poLines, 'exchangeFee'),
                programFees: _.sumBy(poLines, 'programFee'),
                oemFees: _.sumBy(poLines, 'oemFee'),
            },
        }

        dispatch(saveCheckout(checkout));
        setShowConfirmation(true);

    };

    const onBackToCases = () => {
        if (location && location.state && location.state.from) {
            const prevPage = location.state.from.toLowerCase();
            const goback = prevPage.indexOf('/on-site-service') > -1 ||
                prevPage.indexOf('/quotes') > -1;
            if (goback) history.goBack();
            else history.push('/on-site-service');
        } else {
            history.push('/on-site-service');
        }
    }

    return (
        <React.Fragment>
            {page.isPreview() && <>
                <h2>Quote Content:</h2>
                <BrComponent path={'main/quote'} />
                <br />
                <h2>Order Content:</h2>
                <BrComponent path={'main/order'} />
            </>}
            {serviceItem ? (
                <div className="onSite-service-detail">
                    {isQuote ?
                        <React.Fragment>
                            <OnSiteHeader type={type} urgencyId={serviceItem.urgencyId} title={serviceItem.title} quote={quote} />
                            <div className="alert-wrapper">
                                <span className="fa-exclamation-wrapper"><span className="fa fa-exclamation" /></span>
                                <span className="alert-wrapper-text">
                                    Please approve your quote within two hours to lock in this Service Specialist.
                                </span>
                            </div>
                        </React.Fragment> :
                        <React.Fragment>
                            <header className="on-site-header">
                                <section>
                                    <PageMetaData
                                        title="Service Details"
                                        pageType="other"
                                        trackAnalytics={true}
                                    />
                                    <span className="on-site-label chevron">My Account</span>
                                    <span className="on-site-sub-label">On-Site Service</span>
                                </section>
                            </header>
                            <div className="order-header">
                                <div className="back-text" onClick={onBackToCases}>
                                    <i
                                        className="fa fa-chevron-left"
                                        aria-hidden="true"
                                    />Back to Cases</div>
                                <div className="case-number">
                                    Case #{serviceItem.orderId}
                                    {!(serviceItem.serviceInfo && serviceItem.serviceInfo.ticketId !== 0) && <span className="print-order" onClick={onPrintConfirmation}>Print</span> }
                                </div>
                                {serviceItem.urgencyId === 1 && <div className="critical-hard-down">
                                    <span className="fa-exclamation-wrapper"><span className="fa fa-exclamation" /></span>
                                    <span>
                                        Critical Hard Down
                                    </span>
                                </div> }
                                <div className="important-detail">
                                    See the important details below for your active On-Site Service Event including the Service Specialist identified through PRECISION™ Procurement.
                                </div>
                            </div>
                        </React.Fragment>}
                    {!isQuote && isMultiAsset && <ProgressTracker completePercentage={serviceInfo.completePercentage} targetEndDate={ serviceInfo.targetEndDate} />}
                    <OtherInformation
                        item={quote.detail}
                        loadQuoteDetails={loadQuoteDetails}
                        provider={selectedProvider}
                    />

                    {type === ServiceType.QUOTE && <React.Fragment>
                        {isQuote ? <React.Fragment>
                            {isMultiAsset ? <div className="service-providers">Service Providers(s)</div> :
                                <div className="service-providers">{`Technician${technicians.length === `` ? '' : '(s)'}`}</div>
                            }
                            <span className="select-wrapper">
                                <label>Sort By:</label>
                                <span className="select" onClick={() => {
                                    setShowSortByMenu(!showSortByMenu);
                                }}>
                                    {sortType}
                                </span>
                                {showSortByMenu && (
                                    <span className="list-menu" onMouseLeave={() => setShowSortByMenu(false)}>
                                        <span onClick={() => onChangeSortType(SortTypeEnum.Highest)} className={sortType === SortTypeEnum.Highest ? 'selected' : ''}>{SortTypeEnum.Highest}</span>
                                        <span onClick={() => onChangeSortType(SortTypeEnum.Quickest)} className={sortType === SortTypeEnum.Quickest ? 'selected' : ''}>{SortTypeEnum.Quickest}</span>
                                        <span onClick={() => onChangeSortType(SortTypeEnum.Cost)} className={sortType === SortTypeEnum.Cost ? 'selected' : ''}>{SortTypeEnum.Cost}</span>
                                    </span>
                                )}
                            </span>
                        </React.Fragment> :
                            <React.Fragment>
                                <div className="service-providers">Service Provider</div>
                                <div className="service-provider-technician">Technician(s)</div>
                            </React.Fragment>
                        }
                    </React.Fragment>}

                    {type !== ServiceType.QUOTE && type !== ServiceType.PENDING_QUOTE &&
                    <React.Fragment>
                        <div className="service-providers">Service Provider</div>
                        {!hasContract && <div className="service-provider-technician">Technician(s)</div>}
                    </React.Fragment>}

                    {hasContract && serviceInfo && 
                    <div className="vendor-information-section">
                        {serviceInfo.vendorName && <div className="row-line">Vendor Name: <span className="value">{serviceInfo.vendorName}</span></div>}
                        {serviceInfo.vendorPhone && <div className="row-line">Vendor Phone: <span className="value">{getFormattedPhoneNumber(serviceInfo.vendorPhone)}</span></div>}
                        {serviceInfo.vendorEmail && <div className="row-line">Vendor Email: <span className="value">{serviceInfo.vendorEmail}</span></div>}
                    </div>}    

                    {isMultiAsset && isQuote && <Carousel itemWidth="650" responsiveSizes={responsiveSizes}>
                        {serviceProviders.map((x, i) =>
                            (<ServiceProviderCard
                                key={`prov-${x.id}`}
                                provider={x}
                                quote={serviceItem}
                                showServiceProviderName={showServiceProviderName}
                                onAddToCart={loadQuoteDetails}
                                onRemoveFromCart={onRemoveFromCart}
                            />)) }
                    </Carousel>
                    }
                    {(!isMultiAsset || !isQuote) &&
                    <Carousel itemWidth="650" responsiveSizes={responsiveSizes}>
                        {technicians.map((x, i) =>
                            (<ServiceSpecialistCard
                                key={`tech-${x.id}`}
                                technician={x}
                                quote={serviceItem}
                                showServiceProviderName={showServiceProviderName}
                                onAddToCart={loadQuoteDetails}
                                onRemoveFromCart={onRemoveFromCart}
                            />)
                        ) }
                    </Carousel>
                    }
                    {serviceInfo.workOrders.length > 0 && !isQuote && <React.Fragment>
                        <div className="section-divider" />
                        <WorkOrdersGrid data={serviceInfo.workOrders} />
                    </React.Fragment>}
                    <article className="service-commitment">
                        <h1 className="commitment-title">PartsSource Service Commitment</h1>
                        <BrComponent path={`main/${type === ServiceType.QUOTE ? 'quote' : 'order'}`} />
                    </article>
                </div>
            ) : (
                renderBusy()
            )}
            {showConfirmation && <SalesQuote isConfirmation={true} onClose={() => setShowConfirmation(false)} />}
        </React.Fragment>
    );
}

const renderBusy = () => {
    return (
        <div className="full-row load-quote">
            <span className="message">Retrieving your quote...</span>
            <div className="loader" />
        </div>
    );
};

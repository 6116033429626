import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    overflow: hidden;
    margin-right: -50px;
    margin-left: -50px;
    padding-left: 50px;

    
    @media screen and (max-width: 1149px) {
        margin-right: -20px;
        margin-left: -20px;
        padding-left: 20px;
    }
`;

const CarouselContainer = styled.div`
    white-space: nowrap;
    -webkit-transition: margin-left 0.2s linear;
    -moz-transition: margin-left 0.2s linear;
    -o-transition: margin-left 0.2s linear;
    transition: margin-left 0.2s linear;
`;

const LeftArrow = styled.div`
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 7px 0px rgba(167, 167, 167, 0.5);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    cursor: pointer;    
    position: absolute;
    top: calc(50% - 24px);
    left: 30px;

    &:after {
    content: '\f104';
    font-family: FontAwesome;        
    font-style: normal;
    margin: 0px 0px 0px 16px;
    text-decoration: none;
    font-size: 30px;
    color: rgb(108, 108, 108);
    line-height: 45px;
    } 
`;

const RightArrow = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 7px 0px rgb(167 167 167 / 50%);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 24px);
    right: 30px;

    &:after {
        content: '\f105';
        font-family: FontAwesome;        
        font-style: normal;
        margin: 0px 0px 0px 20px;
        text-decoration: none;
        font-size: 30px;
        color: rgb(108, 108, 108);
        line-height: 45px;
    }
`;

// This control assumes the children will already have the applicable styles in place to display 
// horizontally (i.e. inline-block)
export default function Carousel({children, className, itemWidth, responsiveSizes}) {    
    const [carouselLeftMargin, setCarouselLeftMargin] = useState(3);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const [carouselItemWidth] = useState(parseInt(itemWidth || 200));
    const [initialMaxScroll, setInitialMaxScroll] = useState(0);

    const carouselRef = useRef(null);

    const classNamePrefix = className ? className + '-' : '';    

    const initialWindowSize = window.innerWidth;

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            if (window.innerWidth === initialWindowSize) return false;

            setCarouselLeftMargin(3);
            setRightArrowVisiblity();
            setInitialMaxScroll(0);
        }, 200);
  
        window.addEventListener('resize', debouncedHandleResize);          
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    });

    useEffect(() => {
        setShowLeftArrow(carouselLeftMargin < 3);
        setShowRightArrow(!disableNextScroll());
    }, [carouselLeftMargin]);

    useEffect(() => {
        setRightArrowVisiblity();
    }, [carouselRef, children]);
    
    const getItemWidth = () => {        
        if (responsiveSizes) {            
            let windowSize = window.innerWidth;
            let itemSizes = responsiveSizes.filter(x => windowSize >= x.min && windowSize <= x.max);
            if (itemSizes.length > 0) return itemSizes[0].itemSize;            
        } 
          
        return carouselItemWidth;
    }

    const setRightArrowVisiblity = () => {        
        if (carouselRef.current) { 
            const hasOverflow = carouselRef.current.scrollWidth > carouselRef.current.offsetWidth;
            setShowRightArrow(hasOverflow);
        }
    }

    const debounce = (fn, ms) => {
        let timer;
        return _ => {
            clearTimeout(timer);
            timer = setTimeout(_ => {
                timer = null;
                fn.apply(this, arguments);
            }, ms);
        };
    }

    const onScrollLeft = debounce(function scrollCarouselLeft() {               
        const scrollAmount = carouselLeftMargin + getItemWidth();
        setCarouselLeftMargin(scrollAmount > 3 ? 3 : scrollAmount);
    }, 200);

    const onScrollRight = debounce(function scrollCarouselRight() {    
        if (initialMaxScroll === 0) {
            const containerWidth = carouselRef.current.offsetWidth;
            const containerOverflow = carouselRef.current.scrollWidth;  
            const maxScroll = containerOverflow - containerWidth - carouselLeftMargin;
            setInitialMaxScroll(maxScroll);
        }
        setCarouselLeftMargin(carouselLeftMargin - maxScrollAmt());
    }, 200);

    const maxScrollAmt = () => {
        if (!(carouselRef && carouselRef.current)) return 10;
        const containerWidth = carouselRef.current.offsetWidth;
        const containerOverflow = carouselRef.current.scrollWidth;        
        const maxScroll = containerOverflow - containerWidth;
        const itmWidth = getItemWidth();
        const scrollAmount = maxScroll > itmWidth ? itmWidth : maxScroll;        
        return scrollAmount;
    }

    const disableNextScroll = () => {
        if (!(carouselRef && carouselRef.current)) return false; 
        return carouselLeftMargin + initialMaxScroll === 0;
    }

    return (
        <Container ref={carouselRef} className={`${classNamePrefix}ps-carousel-wrapper`}>
            <CarouselContainer className={`${classNamePrefix}ps-carousel`} style={{marginLeft: carouselLeftMargin}}>
                {children}       
            </CarouselContainer>
            {showLeftArrow && <LeftArrow className={`${classNamePrefix}ps-carousel-left-arrow`} onClick={onScrollLeft} />}
            {showRightArrow && <RightArrow className={`${classNamePrefix}ps-carousel-right-arrow`} onClick={onScrollRight} />}
        </Container>
    );
}

import axios from 'axios';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import $ from 'jquery';
import {Button, FileUploader} from '@partssourceinc/react-ui-core';

export default class Photos extends Component {
    static propTypes = {
        photos: PropTypes.array.isRequired,
        assetDetails: PropTypes.object.isRequired,
        backToInfoCenter: PropTypes.func.isRequired,
        lineItemId: PropTypes.number.isRequired,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            isFileSelected: false,
            fileDisplay: '',
            files: [],
            uploadFile: {
                name: '',
                file: null,
            },
            photos: props.photos,
            assetDetails: props.assetDetails,
            showUploadButton: true,
            loading: false,
            errorMessage: '',
            lineItemId: props.lineItemId
        };
    }

    toggleButtonDisplay(value) {
        this.setState({showUploadButton: value});
    }

    componentWillReceiveProps() {
        const {photos} = this.props;
        this.setState({photos});
    }

    handleRemove(e) {
        e.preventDefault();
        this.setState({fileDisplay: '', isFileSelected: false, errorMessage: ''});
        this.toggleButtonDisplay(true);
    }

    processFiles(e) {
        e.preventDefault();
        let files = e.target.files;

        let cleanup = false;
        if (!files) {
            this.setState({errorMessage: 'Your photo was not uploaded. Please try again.'});
            cleanup = true;
        }

        if (files[0].size > 2097152) {
            this.setState({errorMessage: 'File size exceeds the 2MB limit.'});
            cleanup = true;
        }

        if (cleanup) {
            this.setState({isFileSelected: false, fileDisplay: '', fileInput: ''});
            return;
        } else {
            this.setState({errorMessage: ''})
        }

        this.toggleButtonDisplay(false);

        this.setState({
            isFileSelected: true,
            fileDisplay: $(e.target)
                .val()
                .split('/')
                .pop()
                .split('\\')
                .pop(),
        });
        let reader = new FileReader();
        let file = files[0];

        reader.onload = upload => {
            this.setState({
                uploadFile: {
                    file: file.name,
                    sourceFile: upload.target.result,
                },
            });
        };
        reader.readAsDataURL(file);
    }

    savePhoto() {
        const {lineItemId, uploadFile} = this.state;

        this.setState({loading: true});

        let request = {
            ImageName: uploadFile.file,
            data: uploadFile.sourceFile,
        };

        axios
            .post(`ShoppingService/api/v1/lineItems/${lineItemId}/photos`, request)
            .then(resp => {
                if (resp.data) {
                    this.setState({photos: resp.data, loading: false, isFileSelected: false, fileDisplay: ''});                
                    this.toggleButtonDisplay(true);
                }
                else {
                    this.setState({photos: [], errorMessage: 'Your photo was not uploaded. Please try again.', loading: false, isFileSelected: false, fileDisplay: ''});                
                    this.toggleButtonDisplay(true);
                }
            });
    }

    renderPhoto(photo) {
        return (<div className="docDetails">
            <img src={`data:image/jpeg;base64,  ${photo.image}`} />
            <p className="docAttributes">
                <span>Uploaded By: </span>
                <span>
                    {photo.firstName} {photo.lastName}
                </span>
            </p>
            <p>
                <span className="docAttributes">Date: </span>
                <span>
                    {moment(photo.updatedTimestamp).format('dddd, MMMM D, YYYY')}
                </span>
            </p>
        </div>);
    }

    render() {
        const {backToInfoCenter} = this.props;
        const {photos, loading, errorMessage, isFileSelected, fileDisplay, showUploadButton} = this.state;

        return (
            <div className="asset-information-dialog">
                <div>
                    <div className="goBack" onClick={event => backToInfoCenter(event, null, photos)}>
                        <span className="counterSink">⌵</span>
                        <span className="backToInfoCenter">Back to Info Center</span>
                    </div>
                    <div>
                        <h1 className="title">Photos</h1>
                    </div>
                </div>
                <div>
                    <FileUploader
                        label="CHOOSE FILE"
                        restriction="Max file size 2MB"
                        infoCenter={true}
                        photosTab={true}
                        isFileSelected={isFileSelected}
                        fileDisplay={fileDisplay}
                        handleRemove={::this.handleRemove}
                        onChange={::this.processFiles}
                        showUpload={showUploadButton}
                    />
                </div>
                <span className="errorMessage">{errorMessage}</span>
                {isFileSelected && <div>
                    {!loading && <span className="removePhoto" onClick={::this.handleRemove}>
                        REMOVE
                    </span>}
                    <Button className="btnSave" secondary={true} onClick={::this.savePhoto} loading={loading}>
                        SAVE PHOTO
                    </Button>
                </div>}
                <div className="photosTab">
                    <div className="uploadedPhotosWrapper">
                        {photos && photos.length > 0 ? (
                            photos.map(item => {
                                return ::this.renderPhoto(item);
                            })
                        ) : (
                            <p className="noFile">No Photos</p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

import moment from 'moment-timezone';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';

export default class EventLogs extends Component {
    static propTypes = {
        backToInfoCenter: PropTypes.func.isRequired,
        eventLogs: PropTypes.array.isRequired,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            eventLogs: props.eventLogs,
        };
    }

    renderEventLogItems(log) {
        let zoneName = moment.tz.guess();
        let zoneAbbrivated = moment.tz(zoneName).format('z');

        return (
            <div className="eventLogItem">
                <p className="date">
                    {moment(log.timeStamp).format('L hh:mm:ss A ')}
                    {zoneAbbrivated}
                </p>
                <div>
                    <span>Action: </span>
                    <span className="spanValues">{log.description}</span>
                </div>
                <div>
                    <span>By: </span>
                    <span className="spanValues">{log.actionBy}</span>
                </div>
            </div>
        );
    }

    render() {
        const {backToInfoCenter} = this.props;
        const {eventLogs} = this.state;

        return (
            <div>
                <div>
                    <div className="goBack" onClick={event => backToInfoCenter(event)}>
                        <span className="counterSink">⌵</span>
                        <span className="backToInfoCenter">Back to Info Center</span>
                    </div>
                    <div>
                        <h1 className="title">Event Log</h1>
                    </div>
                </div>
                <div className="eventLogsWrapper">
                    {eventLogs && eventLogs.length > 0
                        ? eventLogs.map(item => this.renderEventLogItems(item))
                        : ''}
                </div>
            </div>
        );
    }
}

import React from 'react'
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div`
    background-color: rgb(241, 241, 241);
    padding: 20px 26px;
    position: relative;
    margin-bottom: 20px;
`;

const TargetCompletion = styled.div`
    display: inline-block;
    margin-right: 5px;
    line-height: 22px;
    width: 33%;

    @media(max-width: 690px){ 
        width: 100%;
    }
`;

const TargetDateLabel = styled.div`
    font-size: 16px;    
`;

const TargetDate = styled.div`
    font-weight: 600;
    font-size: 20px;
`;

const Status = styled.div`
    display: inline-block;
    padding-right: 30px;
    line-height: 22px;

    @media(max-width: 690px){ 
        padding-right: 0;
    }
`;

const StatusLabel = styled.div`
    font-size: 16px;

    @media(max-width: 690px){ 
        width: 100%;
        padding-right:0;
        display:block;
        margin-top: 20px;
        position: initial;
    }
`;

const StatusValue = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: rgb(255, 149, 5);
`;

const StatusBar = styled.div`
    display: inline-block;
    background-color: white;
    height: 8px;
    width: calc(100% - 530px);
    max-width: 500px;
    border-radius: 5px;
    position: absolute;
    top: 40px;

    @media(max-width: 820px){
        width: calc(100% - 450px)
    }

    @media(max-width: 690px){ 
        width: 100%;
        position: initial;
        margin-top: 25px;    
        max-width: 100%;    
    }
`;

const StatusBarCompletePercentage = styled.div`
    background-color: rgb(255, 149, 5);
    height: 8px;
    border-radius: 5px;
`;

export default function ProgressTracker({completePercentage, targetEndDate}) {    
    return (
        <Container>
            <TargetCompletion>
                <TargetDateLabel>Target Completion Date:</TargetDateLabel>
                {targetEndDate && <TargetDate>{moment(targetEndDate.replace('-04:00', ''), 'YYYY-MM-DD-h.mm.ss').format('MM/DD/YYYY')}</TargetDate>}
            </TargetCompletion>
            <Status>
                <StatusLabel>Status:</StatusLabel>
                <StatusValue>{completePercentage}% Complete</StatusValue>
            </Status>
            <StatusBar>
                <StatusBarCompletePercentage style={{width: `${completePercentage}%`}} />
            </StatusBar>
        </Container>
    )
}

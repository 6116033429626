import React, {useState} from 'react';
import 'less/onSiteInfo.less';
import AssetInformation from './AssetInformation';
import AttachmentAndNotes from './AttachmentAndNotes';
import InfoCenterMain from 'components/InfoCenter/InfoCenterMain';
import ServiceEventDetails from './ServiceEventDetails';
import {Popup} from '@partssourceinc/react-ui-core';
import {ServiceInfoType} from '../helpers';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {getFSRDocument} from 'utility';
import styled from 'styled-components';
import moment from 'moment';

const FsrLink = styled.div`
    color: #005DA6;
    font-weight: 600;
    cursor: pointer;
    line-height: 30px;
`;

export default function OtherInformation({item, loadQuoteDetails, provider}) {
    const user = useSelector(state => state.user);
    const [detail, setDetail] = useState(item);
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState();
    const [showAttachments,setShowAttachments] = useState(false);
    const [showNotes,setShowNotes] = useState(false);
    const [isUrgency, setIsUrgency] = useState(detail.priority === 1);    
    const [isServiceNeededOpen, setIsServiceNeededOpen] = useState(false);
    const [showMissingDocumentModal, setShowMissingDocumentModal] = useState(false);

    const {
        serviceInfo: {serviceNeeded, turnaroundTime, category, locationDetail, model, serviceType, modelId, resourceOtherCommentsQualification, totalWorkOrders, hasContract, serviceWindowStartDate, serviceWindowEndDate, contactName, contactPhone, contactEmail},
        warranty,
        requestor,
        facilityName,
        manufacturer,
        fields,
        attachments,
        notes,
        dateCreated,
        oemId,
        fsrDocuments,
    } = detail;
    console.log(serviceNeeded);
    const reqFields = detail.fieldValues.filter(x => x.isRequired);
    const optFields = detail.fieldValues.filter(x => !x.isRequired);
    const priority = detail.priority === 1;
    const isQuote = detail.statusId === 26;

    const hasFsrDocuments = fsrDocuments && fsrDocuments.length > 0;

    const handleOnOpenModal = (type) => {
        setType(type);
        setShowModal(true);
    };

    const orderDetailChange = (requester) => {
        let _item = {...detail};
        _item.requestor = requester.fullName;
        _item.requesterId = requester.contactId;
        setDetail(_item);
    }

    const getFieldValues = (values, requester) => {
        const {info: {contactId}} = user;
        axios.all([saveFields(values, contactId), saveRequester(requester, contactId), saveUrgency(detail.lineItemId, detail.priority, isUrgency), loadQuoteDetails()]).catch(err => console.log(err));
    }

    const saveRequester = (requester) => {

        if (requester && requester.contactId) {
            return axios.post('/ShoppingService/api/v1.0/cart/updateRequester', {
                OrderId: detail.orderId,
                UserId: requester.contactId,
            });
        }
        return new Promise((resolve) => resolve());
    }

    const saveUrgency = (lineItemId, priority, urgency) => {
        if ((!urgency && priority === 1) || (urgency && priority === 2))
            return axios.post(`/ShoppingService/api/v1.0/cart/updatePriority/${lineItemId}/${urgency}`);
        return new Promise((resolve) => resolve());
    }

    const saveFields = (fields, contactId) => axios.post(`/ShoppingService/api/v1.0/cart/saveFields/${contactId}`, fields);
    const minDate = moment.utc('0001-01-01'); // minimum value as per UTC
    
    const renderComponent = (type) => {
        switch (type) {
            case ServiceInfoType.ASSET_INFORMATION:
                return (
                    <AssetInformation
                        category={category}
                        oem={manufacturer}
                        equipmentSerial={fields['Equipment Serial #']}
                        assetId={fields['Asset ID']}
                        costCenter={fields['Cost Center']}
                        model={model}
                        oemId={oemId}
                        modelId={modelId}
                    />
                );
            case ServiceInfoType.SERVICE_EVENT_DETAIL:
                return (
                    <ServiceEventDetails
                        timeframe={turnaroundTime}
                        serviceDescription={serviceNeeded}
                        warranty={warranty}
                        facility={facilityName}
                        requestor={requestor}
                        locationDetail ={locationDetail}
                        category={category}
                        dateCreated={dateCreated}
                        serviceType={serviceType}
                        totalWorkOrders={totalWorkOrders}
                        hasContract={hasContract}
                        serviceWindowStartDate={moment.utc(serviceWindowStartDate).isAfter(minDate) ? serviceWindowStartDate : null}
                        serviceWindowEndDate={moment.utc(serviceWindowEndDate).isAfter(minDate) ? serviceWindowEndDate : null}
                        contactName={contactName}
                        contactPhone={contactPhone}
                        contactEmail={contactEmail}
                    />
                );
            case ServiceInfoType.FACILITY_INFORMATION:
                return (
                    <section className="item">
                        <h1 className="section-title">Facility Information</h1>
                        {facilityName && (
                            <div className="row-line">
                                Facility: <span className="value">{facilityName}</span>
                            </div>
                        )}
                        {locationDetail && (
                            <div className="row-line">
                                Location Details: <span className="value">{locationDetail}</span>
                            </div>
                        )}
                    </section>
                )
            case 'FSR Documents':
                return (
                    <section className="item">
                        <h1 className="section-title" style={{marginBottom: '20px'}}>FSR Documentation</h1>  
                        {fsrDocuments.map((d,i) => 
                            <FsrLink data-ea-download-link="FSR Document" key={`${d.lineItemId}-${i}`} onClick={() => onShowFSRDocument(d)}><i className="fa fa-file-text-o" style={{paddingRight: '5px'}} />{d.fileName}</FsrLink>)}                 
                    </section>
                )
        }
    };

    const onShowFSRDocument = (doc) => {
        getFSRDocument(doc).then(x => {
            if (!x) setShowMissingDocumentModal(true);
        });
    }

    return (
        <React.Fragment>
            <div className="on-site-info">
                <div className="ps-hidden-xs">
                    <div className="scope-of-work item col-xs-12 col-sm-12 col-md-4">                        
                        <div className="row-line">
                        Service Needed:
                            <span className="value">
                                {serviceNeeded}
                            </span>
                        </div>                        
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4 asset-service-column">
                        {renderComponent(ServiceInfoType.ASSET_INFORMATION)}
                        {renderComponent(ServiceInfoType.SERVICE_EVENT_DETAIL)}
                    </div>
                    <div className="item col-xs-12 col-sm-6 col-md-4">
                        <div
                            className="row quoteRow attachment-line"
                            onClick={() => setShowAttachments(true)}
                        >
                            <i className="fa fa-copy" aria-hidden="true" />
                            <span className="attachments">{`Attachments(${attachments.length})`}</span>
                        </div>
                        <div
                            className="row quoteRow attachment-line"
                            onClick={() => setShowNotes(true)}
                        >
                            <i className="fa fa-calendar" aria-hidden="true" />
                            <span className="attachments">{`Notes(${notes.length})`}</span>
                        </div>
                        <section className="item" style={{paddingTop: '36px'}}>
                            <h1 className="section-title">Facility Information</h1>
                            {facilityName && (
                                <div className="row-line">
          Facility: <span className="value">{facilityName}</span>
                                </div>
                            )}
                            {locationDetail && (
                                <div className="row-line">
          Location Details: <span className="value">{locationDetail}</span>
                                </div>
                            )}
                        </section>
                        {hasFsrDocuments && 
                        <section className="item">
                            <h1 className="section-title">FSR Documentation</h1> 
                            {fsrDocuments.map((d,i) => 
                                <div className="fsr-link" key={`${d.lineItemId}-${i}`} onClick={() => onShowFSRDocument(d)}><i className="fa fa-file-text-o" style={{paddingRight: '5px'}} />{d.fileName}</div>)}
                        </section>
                        }
                    </div>
                </div>
                <div className="ps-hidden-sm ps-hidden-md ps-hidden-lg ps-hidden-xl mobile-wrapper">
                    {serviceNeeded && <div className={isServiceNeededOpen ? 'chevron-down-section-wrapper open' : 'chevron-down-section-wrapper'}>
                        <div className="section-wrapper-title" onClick={() => setIsServiceNeededOpen(!isServiceNeededOpen)}>
                            <i className={isServiceNeededOpen ? 'fa fa-chevron-down' : 'fa fa-chevron-right'} /> Service Needed</div>
                        <div className="section-wrapper-value"><div className="wrapper-value">{serviceNeeded}</div></div>
                    </div>}                    
                    <div style={{marginTop: '25px'}}>
                        <div className="right-chevron-section" onClick={() => handleOnOpenModal(ServiceInfoType.ASSET_INFORMATION)}>Asset Information</div>
                        <div className="right-chevron-section" onClick={() => handleOnOpenModal(ServiceInfoType.SERVICE_EVENT_DETAIL)}>On-Site Service</div>
                        <div className="right-chevron-section" onClick={() => setShowAttachments(true)}>Attachments ({attachments.length})</div>
                        <div className="right-chevron-section" onClick={() => setShowNotes(true)}>Notes ({notes.length})</div>
                        <div className="right-chevron-section" onClick={() => handleOnOpenModal(ServiceInfoType.FACILITY_INFORMATION)}>Facility Information</div>      
                        {hasFsrDocuments && <div className="right-chevron-section" onClick={() => handleOnOpenModal('FSR Documents')}>FSR Documentation</div>}               
                    </div>
                </div>
            </div>
            
            {showModal && <Popup
                show={showModal}
                hideButtons={true}
                onCancel={() => setShowModal(false)}
            >
                <div style={{lineBreak: 'anywhere'}}>
                    {renderComponent(type)}
                </div>
                
            </Popup>}

            {showAttachments && (
                <InfoCenterMain
                    onCancel={() => setShowAttachments(false)}
                    onSubmit={getFieldValues}
                    onOrderDetailChange={orderDetailChange}
                    reqFields={reqFields}
                    fields={item.fieldValues}
                    optFields={optFields}
                    lineItemId={item.lineItemId}
                    orderId={item.orderId}
                    requesterId={item.requesterId}
                    activeTab="Attachments"
                    priority={priority}
                    onCheckBoxUpdate={(e) => setIsUrgency(e.checked)}
                />
            )}
            {showNotes && (
                <InfoCenterMain
                    reqFields={reqFields}
                    fields={item.fieldValues}
                    optFields={optFields}
                    lineItemId={item.lineItemId}
                    orderId={item.orderId}
                    onCancel={() => setShowNotes(false)}
                    onSubmit={getFieldValues}
                    onOrderDetailChange={orderDetailChange}
                    requesterId={item.requesterId}
                    activeTab="Notes"
                    priority={priority}
                    onCheckBoxUpdate={(e) => setIsUrgency(e.checked)}
                />
            )}

            {showMissingDocumentModal && 
                <Popup show={true} hideButtons={true} onCancel={() => setShowMissingDocumentModal(false)}>
                    <div>This document is not available.</div>
                </Popup>
            }
 
        </React.Fragment>
    );
}

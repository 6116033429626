import React from 'react';
import moment from 'moment';
import {getFormattedPhoneNumber} from 'utility';

export default function ServiceEventDetails({
    timeframe,
    serviceDescription,
    warranty,
    facility,
    requestor,
    locationDetail,
    category,
    dateCreated,
    serviceType,
    totalWorkOrders,
    hasContract,
    serviceWindowStartDate,
    serviceWindowEndDate,
    contactName,
    contactPhone,
    contactEmail,
}) {
    return (
        <div className="item">
            <h1 className="section-title">Service Event Details</h1>
            {
                dateCreated && (
                    <div className="row-line">
                        Date Created: <span className="value">{moment(dateCreated).format('MM/DD/YYYY')}</span>
                    </div>
                )}
            {timeframe && (
                <div className="row-line">
                    Timeframe Needed: <span className="value">{timeframe}</span>
                </div>
            )}
            {(serviceWindowStartDate && serviceWindowEndDate) || timeframe && 
                <div className="row-line">Preferred Service Window: 
                    <span className="value">
                        {serviceWindowStartDate && serviceWindowEndDate ?
                            <span>{moment(serviceWindowStartDate).format('MM/DD/YYYY')} to {moment(serviceWindowEndDate).format('MM/DD/YYYY')}</span> :
                            <span>{timeframe}</span>}
                    </span>
                </div>
            }
            
            {serviceType && serviceType !== ' ' && (
                <div className="row-line">
          Service Type: <span className="value">{serviceType}</span>
                </div>
            )}
            {!hasContract && <div className="row-line">
            Qty of Work Orders: <span className="value">{totalWorkOrders}</span>
            </div>}
            {requestor && (
                <div className="row-line">
          Requester: <span className="value">{requestor}</span>
                </div>
            )}
            <div className="row-line">
                Contract: <span className="value">{hasContract ? 'Yes' : 'No'}</span>
            </div>
            {contactName && <div className="row-line">Contact Name: <span className="value">{contactName}</span></div>}
            {contactPhone && <div className="row-line">Contact Phone: <span className="value">{getFormattedPhoneNumber(contactPhone)}</span></div>}
            {contactEmail && <div className="row-line">Contact Email: <span className="value">{contactEmail}</span></div>}
        </div>
    );
}

import React, {useState} from 'react'
import 'less/OnsiteService/serviceSpecialistCard.less';
import moment from 'moment';
import AddQuoteToCartButton from '../../../components/AddQuoteToCartButton';
import CardViewDetailModal, {CardType} from './CardViewDetailModal';

export default function ServiceSpecialistCard({technician, quote, showServiceProviderName, onAddToCart, onRemoveFromCart}) {
    const option = quote.options.find(o => o.vendorResearchId === parseInt(technician.vendorResearchId));
    const vendorFields = quote.vendorFields;
    const quoteArrivalStatusIds = [26,27,7];
    const arrivalTime = quoteArrivalStatusIds.includes(quote.statusId) ? technician.arrivalTimeCommitment : quote.serviceInfo.arrivalTime;
    const isQuote = quote.statusId === 26;
    const isSingleAsset = quote.serviceInfo.requestType === 'SingleAsset'
    const showAddToCart = quote.options.filter(x => x.inCart).length === 0;
    const [showDetails, setShowDetails] = useState(false);

    const renderScore = (score, city, state) => {
        const fullStars = Math.floor(score);
        const halfStars = score - fullStars;
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= fullStars) stars[i] = 'fa fa-star star';
            else if (halfStars > 0 && i - fullStars === 1) {
                stars[i] = 'fa fa-star-half-o star';
            } else stars[i] = 'fa fa-star-o star';
        }
        return (
            <section className="stars-section">
                <span style={{marginRight: 10}}>{score}</span>
                {stars.map((x,i) => <i key={i} className={x} />)}
                {city && state && <span className="starting-location-section">
                    <img src={`/images/icn_pin.png`} />
                    <span className="location-text">{`${city}, ${state}`}</span>
                </span>}
            </section>
        );
    };
      
    const onAddToCartFromModal = () => {
        setShowDetails(false);
        onAddToCart();
    }

    return (
        <div className="service-specialist-wrapper">
            <div className="service-card-body">
                <div className="header-row">
                    <img src="/images/icn_serviceman.png" alt="Service Specialist" style={{height: 40}} />
                    <div className="header-info">
                        <span className="service-provider">{isQuote ? `Service Specialist ${technician.displayIndex}` : technician.name}</span>
                        {showServiceProviderName && <span className="service-provider-name">{technician.servicePartner}</span>}
                        <span className="star-rating">
                            {renderScore(technician.fSEStarRating, technician.startingLocationCity, technician.startingLocationState)}                       
                        </span>   
                        <span className="view-details" onClick={() => setShowDetails(true)}>View Details <i className="fa fa-clone" aria-hidden="true" /></span>                 
                    </div>
                </div>
                {technician.yearExperiencefrm && <div className="years-of-experience">{technician.yearExperiencefrm}</div>}
                {technician.oEMModality && <div className="info-line single">
                    <span>Specialties:</span>
                    <span className="info-detail">{technician.oEMModality.split(';').join(', ')}</span>                 
                </div>}
                {technician.iSOCertified && <div className="info-line single">
                    <span>Certifications:</span>
                    <span className="info-detail">{technician.iSOCertified}</span>                 
                </div>}
                {technician.trainingCertifications && <div className="info-line single">
                    <span>Training:</span>
                    <span className="info-detail">{technician.trainingCertifications}</span>                 
                </div>}
                {technician.peerHospitals && <div className="info-line">
                    <span>Customers:</span>
                    <span className="info-detail">{technician.peerHospitals}</span>                 
                </div>}
                {technician.workTobePerformed && <div className="info-line">
                    <span>Scope of Work to be Performed:</span>
                    <span className="info-detail">{technician.workTobePerformed}</span>
                </div>}
                {technician.resourceOtherCommentsQualification && <div className="info-line">
                    <span>Additional Service Event Information:</span>
                    <span className="info-detail">{technician.resourceOtherCommentsQualification}</span>
                </div>}
                {technician.similarJobsPerformed && <div className="info-line">
                    <span>Recent PartsSource Work History:</span>
                    <span className="info-detail">{`${technician.similarJobsPerformed} Similar Jobs Performed`}</span>
                </div>}                
            </div>
            
            {(isQuote || isSingleAsset) && (!showAddToCart || option) && <div className="footer-row">
                <div className="footer-details">
                    <div className="flat-rate">
                        <span className="rate">${technician.price}</span>{technician.pricingType === 'Hourly' ? 'Total Customer Quote' : technician.pricingType}
                    </div>
                    {technician.pricingComment && <div className="flat-rate-msg">{technician.pricingComment}</div>}
                    {arrivalTime && <div className="service-specialist-line">
                        Service Specialist Arrival {moment(arrivalTime.replace('-04:00', ''), 'YYYY-MM-DD-h.mm.ss').format('MM/DD/YYYY @ hh:mm A')}
                    </div>}
                </div>
                <div className="add-to-cart">
                    {isQuote && showAddToCart && <AddQuoteToCartButton option={option} quote={quote} vendorFields={vendorFields} onAddToCart={onAddToCart} useSecondaryButton={true} />}
                    {option.inCart && 
                    <div>
                        <div>
                            <i className="fa fa-check-circle-o" style={{fontSize: '20px', color: '#17AB78'}} />
                            <span className="added-to-cart">1 item added to cart</span>
                        </div>
                        <div className="remove-from-cart" onClick={() => onRemoveFromCart(option.lineItemId)}>Remove From Cart</div>
                    </div>}
                </div>
            </div>}
            {showDetails && 
                <CardViewDetailModal 
                    onClose={() => setShowDetails(false)} 
                    info={technician}
                    option={option}
                    vendorFields={vendorFields}
                    arrivalTime={arrivalTime}
                    isQuote={isQuote}
                    isSingleAsset={isSingleAsset}
                    showAddToCart={showAddToCart && isQuote}
                    cardType={CardType.ServiceSpecialist}
                    quote={quote}
                    onAddToCart={onAddToCartFromModal}
                    showServiceProviderName={showServiceProviderName}
                />}
        </div>
    )
}

import axios from 'axios';
import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {TextField, Button, FileUploader} from '@partssourceinc/react-ui-core';
import $ from 'jquery';

export default function Attachment({ backToInfoCenter, assetDetails: propsAssetDetails }) {
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [fileDisplay, setFileDisplay] = useState('');
    const [description, setDescription] = useState('');
    const [uploadFile, setUploadFile] = useState({ name: '', file: null });
    const [assetDetails, setAssetDetails] = useState(propsAssetDetails);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        if (Object.keys(assetDetails).length === 0 && assetDetails.constructor === Object) {
            setAssetDetails(propsAssetDetails);
        }
    }, [propsAssetDetails]);

    const handleRemove = (e) => {
        e.preventDefault();
        setFileDisplay('');
        setErrorMessage('');
        setIsFileSelected(false);
    };

    const updateDescription = (e) => {
        let content = e.target.value;
        if (warningMessage === '') {
            if (content.length >= 120) {
                let trimmedContent = content.substring(0, 120);
                setDescription(trimmedContent);
                setWarningMessage('Please limit the description to 120 characters.');
            } else {
                setDescription(content);
            }
        } else {
            if (content.length < 120) {
                setWarningMessage('');
            }
        }
    }

    const processFiles = (e) => {
        e.preventDefault();
        let files = e.target.files;
        let cleanup = false;
    
        if (!files) {
            setErrorMessage('Your file selected was not uploaded. Please try again.');
            cleanup = true;
        }
    
        if (files[0].size > 2097152) {
            setErrorMessage('File size exceeds the 2MB limit.');
            cleanup = true;
        }
    
        if (cleanup) {
            setIsFileSelected(false);
            setFileDisplay('');
            return;
        } else {
            setErrorMessage('');
        }
    
        setIsFileSelected(true);
        setFileDisplay($(e.target).val().split('/').pop().split('\\').pop());
    
        let reader = new FileReader();
        let file = files[0];
    
        reader.onload = (upload) => {
            setUploadFile({
                name: file.name,
                file: upload.target.result,
            });
        };
        reader.readAsDataURL(file);
    };
    
    const saveAttachment = () => {
        const { file, name } = uploadFile;
        const { lineItemId } = assetDetails;
        setLoading(true);

        let request = {
            name,
            description: description === '' ? ' ' : description,
            data: file,
        };
    
        axios
            .post(`ShoppingService/api/v1/lineItems/saveAttachment/${lineItemId}`, request)
            .then((resp) => {
                if (resp && resp.data) {
                    setAssetDetails(resp.data);
                    setLoading(false);
                    setIsFileSelected(false);
                    setFileDisplay('');
                    setWarningMessage('');
                    setDescription('');
                } else {
                    setLoading(false);
                    setIsFileSelected(false);
                    setFileDisplay('');
                    setWarningMessage('Your file selected was not uploaded. Please try again.');
                    setDescription('');
                }
          });
    };
    
    const convertToBlob = (base64Data, contentType) => {
        contentType = contentType || '';
        let sliceSize = 512;
    
        let byteCharacters = atob(base64Data);
        let byteArrays = [];
    
        for (let currentSlice = 0; currentSlice < byteCharacters.length; currentSlice += sliceSize) {
            let slice = byteCharacters.slice(currentSlice, currentSlice + sliceSize);
        
            let bytesContainer = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                bytesContainer[i] = slice.charCodeAt(i);
            }
        
            let byteArray = new Uint8Array(bytesContainer);
        
            byteArrays.push(byteArray);
        }
    
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };
    
    const saveDocForIE = (e, blob, fileName) => {
        e.preventDefault();
        if (navigator.userAgent.toLowerCase().includes('.net')) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            return false;
        }
    };
    
    const renderAttachment = (assetItem) => {
        let extension = assetItem.name.split('.').pop();
        let blob = new Blob();
    
        switch (extension) {
            case 'pdf':
            case 'csv':
                blob = convertToBlob(assetItem.attachment, 'application/' + extension);
                break;
            case 'doc':
                blob = convertToBlob(assetItem.attachment, 'application/msword');
                break;
            case 'docx':
                blob = convertToBlob(
                assetItem.attachment,
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                );
                break;
            case 'xls':
                blob = convertToBlob(assetItem.attachment, 'application/vnd.ms-excel');
                break;
            case 'xlsx':
                blob = convertToBlob(
                assetItem.attachment,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                );
                break;
            case 'txt':
                blob = convertToBlob(assetItem.attachment, 'text/plain');
                break;
            case 'html':
                blob = convertToBlob(assetItem.attachment, 'text/html');
                break;
            default:
                blob = new Blob();
        }
    
        let blobUrl = '';
        let isIE = false;
        if (!navigator.userAgent.toLowerCase().includes('.net')) {
             blobUrl = URL.createObjectURL(blob);
        } else {
            isIE = true;
        }
    
        return (
            <div className="docDetails">
                {isIE ? (
                <a
                    id="downloadAttachment"
                    onClick={(e) => saveDocForIE(e, blob, assetItem.name)}
                    style={{ cursor: 'pointer' }}
                    download={assetItem.name}
                    className="docName"
                >
                    {assetItem.name}
                </a>
                ) : (
                <a id="downloadAttachment" href={blobUrl} download={assetItem.name} className="docName">
                    {assetItem.name}
                </a>
                )}
                <p className="docAttributes">
                <span>Uploaded:</span>
                <span>
                    {' '}
                    {assetItem.createdBy} - {moment(assetItem.dateCreated).format('dddd, MMMM D, YYYY')}
                </span>
                </p>
                <p>
                <span className="docAttributes">Description: </span>
                <span>{assetItem.description}</span>
                </p>
            </div>
        );
    };


    return (
        <div className="asset-information-dialog">
            <div>
                <div className="goBack" onClick={(event) => backToInfoCenter(event, assetDetails)}>
                <span className="counterSink">⌵</span>
                <span className="backToInfoCenter">Back to Info Center</span>
                </div>
                <div>
                <h1 className="title">Attachments</h1>
                </div>
            </div>
            <div>
                <FileUploader
                label="CHOOSE FILE"
                maxMb="2"
                restriction="Max file size 2MB"
                infoCenter={true}
                isFileSelected={isFileSelected}
                fileDisplay={fileDisplay}
                handleRemove={handleRemove}
                onChange={processFiles}
                />
            </div>
            <div className="attachmentModal">
                <span className="errorMessage" style={{ height: '20px', display: 'block' }}>
                {errorMessage}
                </span>
                <TextField
                id="Description"
                multiLine={true}
                rows={2}
                text={description}
                label="Description"
                placeholder="Description"
                required={true}
                onChange={updateDescription}
                limit={120}
                />
                <p style={{ position: 'absolute' }}>{warningMessage}</p>
            </div>
            <Button
                disabled={!isFileSelected || !description}
                className="btnSave"
                secondary={true}
                onClick={saveAttachment}
                loading={loading}
                style={{ marginTop: '30px' }}
            >
                SAVE FILE
            </Button>
            <div className="uploadedDocsWrapper">
                {assetDetails.attachments && assetDetails.attachments.length > 0 ? (
                assetDetails.attachments.map((item) => renderAttachment(item))
                ) : (
                <p className="noFile">No Files</p>
                )}
            </div>
        </div>
    );
};
import React, {useState, useEffect} from 'react'
import TabStrip from 'components/TabStrip';
import {CustomGrid} from '@partssourceinc/react-ui-core';
import moment from 'moment';
import '../../../less/OnSiteService/workerOrdersGrid.less';

import 'less/customGridApolloTheme.less';
import ExpandingSearchIcon from './ExpandingSearchIcon';

import {MobileView} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {filter} from 'lodash';

const SearchWrapper = styled.div`
    height: 40px;
    overflow: visible;      
    width: 100%;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
`;

const SearchInput = styled.input`
    border: none;
    box-shadow: none;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
    padding-right: 30px;
    border: 1px solid #ced4da;
    background-color: #f1f1f1 !important;
    outline: 0;
    cursor: text;  
    height: 40px;
    border-radius: 3px;      
    width: 100%;
    padding-left: 10px;
    padding-right: 30px;
`;

const SearchInputIcon = styled.span`
    font-size: 18px;
    pointer-events: none;        
    top: 10px;
    color: #4a4a4a;        
    position: absolute;
    right: 10px;
    height: 35px;
`;

export default function WorkOrdersGrid({data, defaultSortColumn, defaultSortDirection}) {
    const [filteredData, setFilteredData] = useState(data);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumn, setSortColumn] = useState(defaultSortColumn);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [page, setPage] = useState(1);
    const [showMobileListView, setShowMobileListView] = useState(false);
    const [mobilePageTitle, setMobilePageTitle] = useState('');

    const headerColumns = [
        {fieldName: 'assetId', displayName: 'Asset ID', canSort: true},
        {fieldName: 'serviceAppointmentStatus', displayName: 'Service Appt Status', canSort: true},
        {fieldName: 'arrivalTime', displayName: 'Arrival Date/Time', canSort: true},
    ];

    const tabData = {
        InProgress: filteredData.filter(x => x.groupName === 'InProgress'),
        Complete: filteredData.filter(x => x.groupName === 'Complete'),
    };

    const tabKeys = [
        {
            id: 'InProgress',
            fieldName: 'groupName',
            value: 'InProgress',
            tabDisplay: 'In Progress',
            count: tabData.InProgress.length,
        },
        {
            id: 'Complete',
            fieldName: 'groupName',
            value: 'Complete',
            tabDisplay: 'Complete',
            count: tabData.Complete.length,
        },
    ];

    const [currentTab, setCurrentTab] = useState(tabKeys[0]);

    useEffect(() => {
        let fltrData = _.cloneDeep(data);

        if (searchTerm && searchTerm !== '') {
            fltrData = fltrData.filter((x) => {
                let searchString = '';
                headerColumns.forEach((hc) => (searchString += (x[hc.fieldName] || '').toString().toLowerCase()));
                return searchString.indexOf(searchTerm) > -1;
            });
        }

        fltrData = _.orderBy(fltrData, [sortColumn], [sortDirection]);
        setFilteredData(fltrData)
        setPage(1);
    }, [searchTerm, sortColumn, sortDirection]);

    const onTabChange = (tab) => {
        setCurrentTab(tab);
        setPage(1);
    };

    const onSort = (columnName) => {
        setSortColumn(columnName.sortColumn);
        setSortDirection(columnName.sortDirection);
    }

    const onSearch = (term) => {
        setSearchTerm(term);
        setPage(1);
    }

    const renderRow = (row) => {
        return (
            <tr>
                <td>{row.assetId}</td>
                <td>{row.serviceAppointmentStatus}</td>
                <td>{row.arrivalTime !== 'Pending' ? moment(row.arrivalTime, 'YYYY-MM-DD-h.mm.ss').format('MM/DD/YYYY @ hh:mm A') : 'Pending'}</td>
            </tr>
        );
    }

    const renderSearchBar = () => {
        return (            
            <SearchWrapper className="wo-search-bar">
                <SearchInput onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} value={searchTerm} placeholder="Search" />
                <SearchInputIcon>
                    <i className="glyphicon glyphicon-search" />
                </SearchInputIcon>
            </SearchWrapper>            
        )
    }

    const openMobileView = (tab) => {
        setMobilePageTitle(tab);
        setShowMobileListView(true);
    }
    
    return (
        <div className="worker-orders-grid"> 
            <div className="wo-desktop-view">
                <div style={{fontSize: '20px', paddingBottom: '8px'}}>
                    <span>Work Orders</span>
                    <span style={{float: 'right'}}><ExpandingSearchIcon onChange={onSearch} /></span>
                </div>
                <TabStrip data={filteredData} tabKeys={tabKeys} currentTab={currentTab.id} onChange={onTabChange} />
                <div className="custom-grid-apollo-theme">
                    <CustomGrid
                        key={`wo-grid-${currentTab.id}`}
                        headerColumns={headerColumns}
                        totalRecords={(tabData[currentTab.id] || []).length}
                        showSearch={false}
                        showPagination={true}
                        defaultSortColumn={sortColumn}
                        defaultSortDirection={sortDirection}
                        onSort={onSort}
                        onSearch={onSearch}
                        defaultPageSize={10}
                        gridClassName="custom-grid"
                        tableClassName="group-table"
                        displayPages={10}
                        useTriangleSortIcon={true}
                        page={page}
                    >
                        {(tabData[currentTab.id] || []).map(x => renderRow(x))}
                    </CustomGrid>
                </div>
            </div>
            <div className="wo-mobile-view">
                <div className="wo-title">
                    Work Orders
                </div>                
                {renderSearchBar()}
                
                <div className="wo-grid">
                    <div className="wo-grid-item inPgrogress" onClick={() => openMobileView('In-Progress')}>
                        In-Progress <span className="icn">{filteredData.filter(d => d.groupName === 'InProgress').length}</span>
                        <i className="fa fa-chevron-right" />
                    </div>
                    <div className="wo-grid-item complete" onClick={() => openMobileView('Complete')}>
                        Complete <span className="icn">{filteredData.filter(d => d.groupName === 'Complete').length}</span>
                        <i className="fa fa-chevron-right" />
                    </div>
                </div>
            </div>
            {showMobileListView && <div>
                <MobileView className="mobile-wo-page" 
                    style={{marginTop: '0px', zIndex: 1049}}                     
                    title={mobilePageTitle} 
                    onClose={() => setShowMobileListView(false)} 
                    header={renderSearchBar()}
                >
                    {filteredData.filter(x => x.groupName === mobilePageTitle.replace('-', '')).map(m => (
                        <div className="wo-section-wrapper" key={`wo-item-${m.woNumber}`}>
                            <div className="wo-section">
                                <div className="wo-title">Asset ID</div>
                                <div className="wo-value">{m.assetId}</div>
                            </div>
                            <div className="wo-section">
                                <div className="wo-title">Service Appt Status</div>
                                <div className="wo-value">{m.woStatus}</div>
                            </div>
                            <div className="wo-section">
                                <div className="wo-title">Arrival Date/Time</div>
                                <div className="wo-value">{m.arrivalTime !== 'Pending' ? moment(m.arrivalTime, 'YYYY-MM-DD-h.mm.ss').format('MM/DD/YYYY @ hh:mm A') : 'Pending'}</div>
                            </div>                            
                        </div>
                    ))}
                </MobileView>
            </div>}
        </div>
    )
}

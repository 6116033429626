export const ServiceType = {
    PENDING_QUOTE: 'Pending Quote',
    ORDER: 'Order',
    QUOTE: 'Quote',
};

export const TabType = {
    SCOPE_OF_WORK: 0,
    ADDITIONAL_INFO: 1,
    EXPERIENCE_AND_SPECIALTIES: 2,
    LOCATION: 3,
};

export const ServiceInfoType = {
    ASSET_INFORMATION: 0,
    ATTACHMENTS: 1,
    NOTES: 2,
    SERVICE_EVENT_DETAIL: 3,
    FACILITY_INFORMATION: 4,
};

export const getBackgroundLayout = (type) => {
    switch (type) {
        case ServiceType.ORDER:
            return {background: 'linear-gradient(0, #f1f1f0 68.5%, white 31.5%)'};
        case ServiceType.PENDING_QUOTE:
            return {background: 'linear-gradient(0, #f1f1f0 55%, white 45%)'};
        default:
            return {background: 'linear-gradient(0, #f1f1f0 66%, white 34%)'};
    }
};

export const getSubTitle = (type) => {
    switch (type) {
        case ServiceType.PENDING_QUOTE:
            return 'Thank you for your service request. We will respond with a customized quote as soon as possible.';
        case ServiceType.ORDER:
            return 'See the important details below for your active On-Site Service Event including the Service Specialist identified through PRECISION™ Procurement.';
        case ServiceType.QUOTE:
            return 'We are excited to provide you with this PartsSource On-Site Service Solution.';
        default:
            '';
    }
};

export const getType = (statusId, extendedStatus = null) => {
    const pendingQuoteStatuses = [1, 2, 3, 9];

    if (pendingQuoteStatuses.some((x) => x === statusId))
        return ServiceType.PENDING_QUOTE;
    else if (statusId === 26) {
        if (extendedStatus === 28 || extendedStatus === 30)
            return ServiceType.ORDER;
        return ServiceType.QUOTE;
    } else if (statusId === 4) return ServiceType.QUOTE;
    return ServiceType.ORDER;
};

export const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item.value,
        };
    }, initialValue);
};

export const getRateType = (pricingType) => {
    switch (pricingType) {
        case 'Hourly':
            return 'Hourly Rate';
        case 'FlatRate':
            return 'Flat Rate';
    }
};


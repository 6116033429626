import React, {useState} from 'react'
import styled from 'styled-components';
import {classNames} from 'utility';

const Container = styled.div`
display: inline-block;
align-self: flex-end !important;
height: 14px;
overflow: visible;
margin-right: 0;
width: 300px;
margin-top: 8px;

&--no-margin {
  @media (max-width: @screen-sm-max) {
    margin-right: 0px;
  }
}

@media (max-width: @screen-sm-max) {
  align-self: auto !important;
}

@media (max-width: @screen-md-max) {
  height: auto;
  width: auto;
  flex: 1;
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  margin-top: 20px;
  margin-right: 0px;
}

.hidden-search-box {
  border: none;
  box-shadow: none;
  font-size: 14px;
  height: 40px;
  cursor: pointer;
  padding-right: 30px;

  @media (max-width: @screen-md-max) {
    border: 1px solid #ced4da;
    background-color: #f1f1f1 !important;
    outline: 0;
    cursor: text;
  }

  &.is-visible {
    border: 1px solid #ced4da;
    background-color: #f1f1f1 !important;
    outline: 0;
    cursor: text;
  }
}

.glyphicon-search {
  float: right;
  font-size: 18px;
  pointer-events: none;
  right: 10px;
  top: -28px;
  color: #4a4a4a;
}
`;

export default function ExpandingSearchIcon({onChange, searchTerm}) {
    const [showSearchBox, setShowSearchBox] = useState(false);

    const searchBoxClasses = {
        'form-control': true,
        'form-control hidden-search-box': true,
        'is-visible': showSearchBox,
    };

    const onSearchFocus = () => {
        setShowSearchBox(true);
    };

    const onSearchBlur = (e) => {
        setShowSearchBox(e.target.value !== '');
    };

    const onSearch = (e) => {
        onChange(e.target.value.toLowerCase());
    };

    return (
        <Container>
            <input
                type="search"
                className={classNames(searchBoxClasses)}
                onFocus={onSearchFocus}
                onBlur={onSearchBlur}
                onChange={onSearch}
                autoComplete="grid-search"
            />
            <i className="glyphicon glyphicon-search" />
        </Container>
    )
}

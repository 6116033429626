import React, {useState} from 'react'
import 'less/OnsiteService/serviceProviderCard.less';
import moment from 'moment';
import AddQuoteToCartButton from '../../../components/AddQuoteToCartButton';
import CardViewDetailModal, {CardType} from './CardViewDetailModal';

export default function ServiceProviderCard({provider, quote, showServiceProviderName, onAddToCart, onRemoveFromCart}) {
    const option = quote.options.find(o => o.vendorResearchId === parseInt(provider.vendorResearchId));
    const vendorFields = quote.vendorFields;
    const quoteArrivalStatusIds = [26,27,7];
    const arrivalTime = quoteArrivalStatusIds.includes(quote.statusId) ? provider.arrivalTimeCommitment : quote.serviceInfo.arrivalTime;
    const showAddToCart = quote.options.filter(x => x.inCart).length === 0;
    const [showDetails, setShowDetails] = useState(false);

    const onAddToCartFromModal = () => {
        setShowDetails(false);
        onAddToCart();
    }

    return (
        <div className="service-provider-wrapper">
            <div className="service-card-body">
                <div className="header-row">
                    <img src="/images/icn_repair.png" alt="Service Repair" style={{height: 40}} />
                    <div className="header-info">
                        <span className="service-provider">{showServiceProviderName ? provider.vendorName : `Service Provider ${provider.displayIndex}`}</span>
                        {provider.pQMScore && <span className="pqm-score">PQM = {provider.pQMScore}</span>}
                        <span className="view-details" onClick={() => setShowDetails(true)}>View Details <i className="fa fa-clone" aria-hidden="true" /></span>
                    </div>
                </div>
                {provider.partnerAccountrISOCertified && <div className="info-line single">
                    <span>Certifications:</span>
                    <span className="info-detail">{provider.partnerAccountrISOCertified}</span>                 
                </div>}
                {provider.partnerAccountrOEMModality && <div className="info-line single">
                    <span>Specialties:</span>
                    <span className="info-detail">{provider.partnerAccountrOEMModality.split(';').join(', ')}</span>                 
                </div>}
                {provider.partnerAccountrTrainingandCertifications && <div className="info-line single">
                    <span>Training:</span>
                    <span className="info-detail">{provider.partnerAccountrTrainingandCertifications}</span>                 
                </div>}
                {provider.partnerAccountrPastCustomers && <div className="info-line">
                    <span>Customers:</span>
                    <span className="info-detail">{provider.partnerAccountrPastCustomers}</span>                 
                </div>}
                {provider.partnerAccountrTotalRecentWorkCompletedfrm && <div className="info-line">
                    <span>Recent PartsSource Work History:</span>
                    <span className="info-detail">{provider.partnerAccountrTotalRecentWorkCompletedfrm}</span>
                </div>}
                {provider.summary && <div className="info-line">
                    <span>Summary:</span>
                    <span className="info-detail">{provider.summary}</span>
                </div>}
                {provider.workTobePerformed && <div className="info-line">
                    <span>Scope of Work to be Performed:</span>
                    <span className="info-detail">{provider.workTobePerformed}</span>
                </div>}
                {provider.resourceOtherCommentsQualification && <div className="info-line">
                    <span>Additional Service Event Information:</span>
                    <span className="info-detail">{provider.resourceOtherCommentsQualification}</span>
                </div>}
            </div>  
            {option && <div className="footer-row">
                <div className="footer-details">
                    <div className="flat-rate">
                        <span className="rate">${provider.price}</span>{provider.pricingType === 'Hourly' ? 'Total Customer Quote' : provider.pricingType}
                    </div>
                    {provider.pricingComment && <div className="flat-rate-msg">{provider.pricingComment}</div>}
                    {arrivalTime && <div className="service-specialist-line">
                        Service Specialist Arrival {moment(arrivalTime.replace('-04:00', ''), 'YYYY-MM-DD-h.mm.ss').format('MM/DD/YYYY @ hh:mm A')}
                    </div>}
                </div>
                <div className="add-to-cart">
                    {showAddToCart && <AddQuoteToCartButton option={option} quote={quote} vendorFields={vendorFields} onAddToCart={onAddToCart} useSecondaryButton={true} />}
                    {option.inCart && 
                    <div>
                        <div>
                            <i className="fa fa-check-circle-o" style={{fontSize: '20px', color: '#17AB78'}} />
                            <span className="added-to-cart">1 item added to cart</span>
                        </div>
                        <div className="remove-from-cart" onClick={() => onRemoveFromCart(option.lineItemId)}>Remove From Cart</div>
                    </div>}
                </div>
            </div>}
            {showDetails && 
                <CardViewDetailModal 
                    onClose={() => setShowDetails(false)} 
                    info={provider}
                    option={option}
                    vendorFields={vendorFields}
                    arrivalTime={arrivalTime}                                        
                    showAddToCart={showAddToCart}
                    cardType={CardType.ServiceProvider}
                    quote={quote}
                    onAddToCart={onAddToCartFromModal}
                    showServiceProviderName={showServiceProviderName}
                />}
        </div>
    )
}

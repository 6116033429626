import axios from 'axios';
import moment from 'moment';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {TextField, Button} from '@partssourceinc/react-ui-core';
import {getFormattedPhoneNumber} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';

@connect(state => ({user: state.user}))
export default class Notes extends Component {
    static propTypes = {
        backToInfoCenter: PropTypes.func.isRequired,
        assetDetails: PropTypes.object.isRequired,
        isAllNotes: PropTypes.bool.isRequired,
        loadingNotes: PropTypes.bool,
        showOnlyConditionNotes: PropTypes.bool
    };
    
    constructor(props) {
        super(props);

        this.state = {
            note: '',
            warningMessage: null,
            assetDetails: props.assetDetails,
            loading: false,
        };
    }

    componentDidUpdate(previousProps) {
        // const {assetDetails} = this.state;
        // const {assetDetails: newAssetDetails} = this.props;
        
        // if (Object.keys(assetDetails).length === 0 && assetDetails.constructor === Object) {
        //     this.setState({assetDetails: newAssetDetails});
        // }
        if (previousProps.assetDetails != this.props.assetDetails) {
            this.setState({assetDetails: this.props.assetDetails});
        }
    }

    saveNote() {
        const {note} = this.state;
        const {assetDetails: {lineItemId}} = this.props;

        this.setState({loading: true});

        let request = {text: note};
        axios
            .post(`ShoppingService/api/v1/lineItems/saveNote/${lineItemId}`, request)
            .then(resp => this.setState({assetDetails: resp.data, loading: false}));

        this.setState({note: '', warningMessage: null});
    }

    updateNote(e) {
        let content = e.target.value;

        if (/^[\s]+$/i.test(content)) {
            this.setState({
                warningMessage: ' ',
            });
            return;
        } else {
            this.setState({
                warningMessage: null,
            });
        }
        
        if (content.length >= 120) {
            let trimmedContent = content.substring(0, 120);
            this.setState({note: trimmedContent, warningMessage: 'Please limit your note to 120 characters.'});
        } else {
            this.setState({note: content, warningMessage: null});
        }
    }   
    
    renderNotes(note) {
        return (
            <div className="note">
                <div>
                    <p className="noteTitle">
                        <span>{moment(note.dateCreated).format('MM/DD/YYYY hh:mm A')}</span>
                    </p>
                    <p className="noteContent">{note.text}</p>
                </div>
            </div>
        );
    }

    render() {
        const {backToInfoCenter, isAllNotes, conditionNotes, loadingNotes, showOnlyConditionNotes} = this.props;
        const {assetDetails, note, loading, warningMessage} = this.state;
        const {user: {settings}} = this.props;

        const dedicatedPhoneNumber = settings.dedicatedPhoneNumber && getFormattedPhoneNumber(settings.dedicatedPhoneNumber) !== defaultPhoneNumber ? 
            getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : null;

        let notes = [];
        if (conditionNotes && conditionNotes.length > 0)
            notes = conditionNotes
        else if (assetDetails.notes && assetDetails.notes.length > 0 && isAllNotes)
            notes = assetDetails.notes.filter(x => (x.vendorResearchId == assetDetails.vendorResearchId || !x.vendorResearchId));
        else
            notes = assetDetails.notes;
        
        return (
            <div className="notesPage">
                {conditionNotes && conditionNotes.length > 0 ? <h1 className="title">Notes</h1> :
                    <React.Fragment>
                        <div>
                            <div className="goBack" onClick={event => backToInfoCenter(event, assetDetails)}>
                                <span className="counterSink">⌵</span>
                                <span className="backToInfoCenter">Back to Info Center</span>
                            </div>
                            <div>
                                <h1 className="title">Order Notes</h1>
                            </div>
                        </div>
                        <TextField
                            id="Note"
                            multiLine={true}
                            rows={2}
                            text={note}
                            label="New Note"
                            placeholder="New Note"
                            required={true}
                            onChange={::this.updateNote}
                            limit={120}
                        />
                        <p style={{'position': 'absolute'}}>{warningMessage}</p>
                        <Button
                            style={{'margin-top': '30px'}}
                            disabled={!note || warningMessage}
                            className="btnSave"
                            secondary={true}
                            onClick={::this.saveNote}
                            loading={loading}
                        >
                    SUBMIT
                        </Button>
                    </React.Fragment>}
                <div className="notesWrapper">
                    {
                        loadingNotes ? <p className="noFile">Loading notes...</p>  :
                        showOnlyConditionNotes && conditionNotes && conditionNotes.length > 0 ? conditionNotes.map(note =>this.renderNotes(note)) : // Condition Notes
                        !showOnlyConditionNotes && notes && notes.length > 0 ? notes.map(note => this.renderNotes(note)) : // Order Notes
                            <p className="noFile">No Notes</p>
                     }
                </div>
                {dedicatedPhoneNumber && <div style={{fontSize: '16px'}}>Call us at <a style={{textDecoration: 'underline'}} href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a></div>}
            </div>
        );
    }
}

import React from 'react';
import axios from 'axios';
import {Button} from '@partssourceinc/react-ui-core';
import {useSelector, useDispatch} from 'react-redux';
import {getCompanyAndVendorFields, getPartCondition, getPurchaseChoice} from 'productUtility';
import {getCookie} from 'utility';
import {actionCreators as cartActions} from 'stores/Cart'
import {actionCreators as UserActions} from 'stores/User';
import {useState} from 'react';
import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';

export default function AddQuoteToCartButton({quote, vendorFields, onAddToCart, option, useSecondaryButton}) {
    const sessionStorageAvailable = useSelector(state => state.network.sessionStorageAvailable);
    const contactId = useSelector(state => state.user.info.contactId);
    const companyFields = useSelector(state => state.user.settings.requiredFields);
    const facilities = useSelector(state => state.user.facilities);
    const groupId = (facilities.find(x => parseInt(x.facilityId) === quote.facilityId) || {}).groupId || 0;
    
    const dispatch = useDispatch();
    const {loadUserCart} = cartActions;
    const {addLegacyQuoteItemToCart} = UserActions;

    const [showAssetInformationAddToCartPopup, setShowAssetInformationAddToCartPopup] = useState(false);
    const [addingToCart, setAddingToCart] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [isUrgency, setIsUrgency] = useState(false);

    const verifyRequiredFields = () => {
        const showFormulary = !option.isFormularyOption && option.nonFormularyApprovalRequired;
        
        // If it has fields from url, set values
        const urlFields = (sessionStorageAvailable && sessionStorage.urlFields) ? JSON.parse(sessionStorage.urlFields) : [];
        const hasUrlFields = urlFields && urlFields.length > 0;

        if (_.isEmpty(quote.fields) || vendorFields.filter(x => x.companyId === option.vendorId).length > 0 || showFormulary || hasUrlFields) {
            let customFlds = getCompanyAndVendorFields(false, false, option, quote.facilityId).map(field => {
                // If a quote was created in PartsFinder, for some reason, the fields don't get their lineItemId and orderId set
                return !field.lineItemId || field.lineItemId === 0 ? _.assign(field, {lineItemId: quote.lineItemId, orderId: quote.orderId}) : field;
            });
            // If it has fields from url, set values
            if (hasUrlFields) {
                customFlds = customFlds.map(field => {
                    const urlField = urlFields.find(f => f.fieldUid === field.fieldUid);
                    if (urlField) {
                        field.value = urlField.value;
                    }
                    return field;
                });
            }

            if (quote.isServiceItem && quote.serviceInfo && quote.serviceInfo.requestType === 'MultiAsset') {
               
                customFlds = customFlds.map(field => {
                    if (field.fieldUid === '11111111-1111-1111-1111-111111111111' || 
                        field.fieldUid === '44444444-4444-4444-4444-444444444444') {
                        field.isRequired = false;
                    }
                    
                    return field;
                });
                  
            }
            
            const hasRequiredFields = customFlds.filter(x => x.isRequired).length > 0;
            if (hasRequiredFields || showFormulary || hasUrlFields) {
                setShowAssetInformationAddToCartPopup(true);
                setCustomFields(customFlds);
                return;
            }
        }

        if (!showFormulary)
            saveFormularyFields([]);

        addToCart();
    }

    const saveFormularyFields = (fields) => {
        fields.forEach(x => x.lineItemId = quote.lineItemId);
        return axios.post('/ShoppingService/api/v1.0/cart/saveFormularyFields', fields || []);
    }

    const saveFields = (fields) => {
        return axios.post(`/ShoppingService/api/v1.0/cart/saveFields/${contactId}`, fields);
    }

    const addToCart = () => {
        option.loading = true;
        const id_ins = getCookie('id_ins');

        const quoteOption = {
            lineItemId: quote.lineItemId,
            purchaseChoiceId: option.purchaseChoiceId,
            vendorResearchId: option.vendorResearchId,
            userId: contactId,
            groupId: groupId,
            imagePath: (quote.thumbnailImage || {}).fullPath,
            customerOrderKey: sessionStorageAvailable && (sessionStorage.wo_id ? sessionStorage.wo_id : ''),
            id_ins: id_ins,
        };

        dataLayer.push({
            'event': 'brAddToCart',
            'brProductId': quote.oItemNumber,
            'brProductSku': quote.oItemNumber,
        }, {
            event: 'addToCart',
            ecommerce: {
                add: {
                    products: [{
                        name: quote.partNumber,
                        id: quote.oItemNumber,
                        price: option.price,
                        brand: quote.manufacturer,
                        category: quote.modalityId,
                        variant: `${getPartCondition(option.conditionId)} -  ${getPurchaseChoice(option.purchaseChoiceId)}`,
                        quantity: quote.quantity,
                    }],
                    id_ins,
                },
            },
        });

        setAddingToCart(true);
        dispatch(addLegacyQuoteItemToCart(quoteOption)).then(x => {
            dispatch(loadUserCart());
            onAddToCart();
            setAddingToCart(false);
        });
    }

    const renderAssetInformationPopup = () => {
        const priority = (quote.priority === 1);
        const showFormulary = !option.isFormularyOption && option.nonFormularyApprovalRequired;
        return (
            <AssetInformationForm
                fields={customFields}
                onSubmit={saveAndProceedToCart}
                formType={AssetFormType.Both}
                readOnly={false}
                onCancel={() => setShowAssetInformationAddToCartPopup(false)}
                showRequester={false}
                allowMissingFields={false}
                priority = { priority }
                hideSaveButton = {true}
                onCheckBoxUpdate = {(e) => setIsUrgency(e.checked)}
                showFormulary={showFormulary}
            />
        );
    }

    const saveAndProceedToCart = (values, formType, requester, isUrgency, formularyFields) => {
        axios.all([saveFields(values), saveFormularyFields(formularyFields)]).then(() => {
            addToCart();
            setShowAssetInformationAddToCartPopup(true);
        });
    }

    return (
        <React.Fragment>
            <Button data-ea-cta-link={option.condition}
                secondary={useSecondaryButton}
                loading={addingToCart}
                onClick={verifyRequiredFields} 
                className="button button-secondary"
                disabled={!option}>
                Add to Cart
            </Button>
            {showAssetInformationAddToCartPopup ? renderAssetInformationPopup() : null}
        </React.Fragment>
    )
}

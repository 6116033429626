import axios from 'axios';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {TextFieldSelect} from '@partssourceinc/react-ui-core';
export default class OrderDetails extends Component {
    static propTypes = {
        backToInfoCenter: PropTypes.func.isRequired,
        assetDetails: PropTypes.object.isRequired,
        requesterId: PropTypes.number.isRequired,
        orderId: PropTypes.number.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            selectedRequester: {},
            showErrors: false,
            assetDetails: props.assetDetails,
            contacts: [],
            loading: false,
        };
    }

    componentWillMount() {
        const {assetDetails} = this.state;

        axios.get(`ShoppingService/api/v1/company/contacts/${assetDetails.facilityId}`)
            .then(resp => this.setState({contacts: resp.data}));
    }

    componentDidMount() {
        const {contacts} = this.state;
        const {requesterId} = this.props;
        let selectedRequester = contacts.find(c => c.contactId === requesterId);
        this.setState({selectedRequester: selectedRequester});
    }

    saveRequester() {
        const {orderId, requesterId, onSubmit} = this.props;
        const {contacts, selectedRequester: cachedSelectedRequester} = this.state;

        this.setState({loading: true});
        let selectedRequester = contacts.find(c => c.contactId === requesterId);

        axios
            .post(`ShoppingService/api/v1.0/cart/updateRequester`, {
                OrderId: orderId,
                UserId: cachedSelectedRequester ? cachedSelectedRequester.contactId : selectedRequester.contactId,
            }).then(() => {
                this.setState({loading: false});
                onSubmit(cachedSelectedRequester.selectedRequester);
            });
    }

    render() {
        const {backToInfoCenter, requesterId} = this.props;
        const {contacts, assetDetails, selectedRequester: cachedSelectedRequester, showErrors} = this.state;

        let selectedRequester = contacts.find(c => c.contactId === requesterId);

        if (!assetDetails || contacts.length === 0 || !cachedSelectedRequester && !selectedRequester) {
            return <div />;
        }
        
        const removeMargin = {
            margin: 0,
        }
        
        return (
            <div>
                <div>
                    <div className="goBack" onClick={event => backToInfoCenter(event, assetDetails)}>
                        <span className="counterSink">⌵</span>
                        <span className="backToInfoCenter">Back to Info Center</span>
                    </div>
                    <div>
                        <h1 className="title">Order Details</h1>
                    </div>
                </div>
                <div className="orderDetailsWrapper">
                    <p className="subtitle">Facility Information</p>
                    <div>
                        <p className="contentLine">
                            <span>Facility: </span>
                            <span className="contentValue">{assetDetails.facilityName}</span>
                        </p>
                        <p className="contentLine">
                            <span>Shipping Address: </span>
                            {assetDetails.shippingAddress[0] !== ',' && assetDetails.shippingAddress[1] !== ' '
                            && <span className="contentValue">
                                {assetDetails.shippingAddress}
                            </span>}
                        </p>
                    </div>
                    <p className="subtitle" style={{'margin-top': '10px'}}>
                        Request Information
                    </p>
                    <p className="contentLine">
                        <span>Requester: </span>
                        <span className="contentValue">
                            {cachedSelectedRequester ? cachedSelectedRequester.fullName : selectedRequester.fullName}
                        </span>
                    </p>
                    <p className="contentLine">
                        <span>Date Entered: </span>
                        <span className="contentValue">
                            {moment(assetDetails.dateCreated).format('MM/DD/YYYY')}
                        </span>
                    </p>
                    <p className="contentLine">
                        <span>Entered By: </span>
                        <span className="contentValue">{assetDetails.requestor}</span>
                    </p>
                </div>
           
            </div>
        );
    }
}

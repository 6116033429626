import React from 'react';
import 'less/TabStrip.less';

export default function TabStrip(props) {
    const {data, tabKeys, onChange, currentTab} = props;

    const getBadgeCount = (x) => {
        return data.filter(d => d[x.fieldName] == x.value).length
    };

    return (
        <div className="tab-strip-control full-row">
            {tabKeys.map((x, i) =>
                (<label className={currentTab === x.id ? 'checked' : ''} onClick={() => onChange(x)} key={i}>
                    {x.tabDisplay} <span>{getBadgeCount(x).toLocaleString()}</span>
                </label>)
            )}
        </div>);
}

import PropTypes from 'prop-types';
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import {Popup} from '@partssourceinc/react-ui-core';
import {ProductImage} from '@partssourceinc/react-ui-core';
import {leadTimeDescription, leadTimeDescriptionRepair} from 'productUtility';
import {formatMoney} from 'utility';
import $ from 'jquery';

import 'less/salesQuote.less';
import axios from 'axios';

@withRouter
@connect((state) => ({hideNoEsdMessaging: state.system.siteSettings.hideNoEsdMessaging}))
export default class LineQuote extends Component {
    static propTypes = {
        quote: PropTypes.object.isRequired,
        hidePricing: PropTypes.bool.isRequired,
        ...ReactRouterPropTypes,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingPdf: false,
        }
    }

    printMe() {
        const {location: {pathname}} = this.props;
        const isReceipt = pathname.indexOf('confirmation') !== -1;

        let contents = $('#sales-quote').html();
        let frame1 = $('<iframe />');
        frame1[0].name = 'frame1';
        frame1.css({'position': 'absolute', 'top': '-1000000px'});
        $('body').append(frame1);
        let frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[0].contentDocument.document : frame1[0].contentDocument;
        frameDoc.document.open();
        // Create a new HTML document.
        frameDoc.document.write(`<html><head><title>Sales ${isReceipt ? 'Receipt' : 'Quote'}</title></head><body>`);
        // Append the external CSS file.
        frameDoc.document.write(`<link href="${window.location.origin}/css/salesQuote.css?v=2" rel="stylesheet" type="text/css" />`);
        
        // Append the DIV contents.
        frameDoc.document.write(contents);
        frameDoc.document.write('</body></html>');

        frameDoc.document.close();
        setTimeout(function () {
            window.frames.frame1.focus();
            window.frames.frame1.print();
            frame1.remove();
        }, 500);
    }

    openPdf() {
        const {location: {pathname}, isConfirmation} = this.props;
        const isReceipt = pathname.indexOf('confirmation') !== -1 || isConfirmation;

        this.setState({loadingPdf: true});

        let axiosConfig = {
            headers: {'Content-Type': 'application/json', 'Accept': 'application/pdf'},
            responseType: 'arraybuffer',
        };
        const pdfUrl = `/CustomerCommunicationsService/api/v1/report/GeneratePdf`;
        const html = `<link href="${window.location.origin}/css/salesQuotePDF.css?v=1" rel="stylesheet" type="text/css" /> ` + $('#sales-quote').html();
        axios.post(pdfUrl, {html: html}, axiosConfig).then(x => {
            const data = x.data;
            const file = new Blob([data], {type: 'application/pdf'});

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                this.setState({loadingPdf: false});
                window.navigator.msSaveOrOpenBlob(file);
                return;
            }

            const fileURL = URL.createObjectURL(file);
            let link = document.createElement('a');
            link.type = 'hidden';
            link.href = fileURL;
            link.download = isReceipt ? 'Receipt.pdf' : 'Quote.pdf';
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            }, 100);

            this.setState({loadingPdf: false});
        });
    }

    renderQuote() {
        let {quote: {detail: {title, lineItemId, dateCreated, partNumber, orderType, quantity, facilityName, requestor,modelNumber, fields, serviceInfo, imagePath}, options}, hidePricing} = this.props;        
        return (<div>
            <div className="row quoteRow" style={{paddingTop: '25px'}}>
                <div className="col-md-9 quoteCol">
                    <div className="slick-title">{title}</div>
                </div>
                <div className="col-md-3" />
            </div>

            <div className="row quoteRow pdfBlock" style={{paddingTop: '25px'}}>
                <div className="col-md-4">
                    {lineItemId && <div className="row quoteRow">
                        <div className="item-attribute">
                            <span>Ref #:</span>
                            <span>{lineItemId}</span>
                        </div>
                    </div>}
                    {dateCreated && <div className="row quoteRow">
                        <div className="item-attribute">
                            <span>Date Entered:</span>
                            <span>
                                {new Date(dateCreated).toLocaleDateString('en-US')}
                            </span>
                        </div>
                    </div>}
                    {partNumber && <div className="row quoteRow">
                        <div className="item-attribute">
                            <span>Item #:</span>
                            <span>{partNumber}</span>
                        </div>
                    </div>}
                    {orderType && <div className="row quoteRow">
                        <div className="item-attribute">
                            <span>Order Type:</span>
                            <span>{orderType}</span>
                        </div>
                    </div>}
                    {quantity && <div className="row quoteRow">
                        <div className="item-attribute">
                            <span>Quantity:</span>
                            <span>{quantity}</span>
                        </div>
                    </div>}
                    {facilityName && <div className="row quoteRow">
                        <div className="item-attribute">
                            <span>Facility:</span>
                            <span>{facilityName}</span>
                        </div>
                    </div>}
                    {requestor && <div className="row quoteRow">
                        <div className="item-attribute">
                            <span>Requester:</span>
                            <span>{requestor}</span>
                        </div>
                    </div>}
                    {serviceInfo && (
                        <React.Fragment>
                            {serviceInfo.serviceType && <div className="row quoteRow">
                                <div className="item-attribute">
                                    <span>Service Type:</span>
                                    <span>{serviceInfo.serviceType}</span>
                                </div>
                            </div>}
                            {serviceInfo.serviceNeeded && <div className="row quoteRow">
                                <div className="item-attribute">
                                    <span>Service Description:</span>
                                    <span>{serviceInfo.serviceNeeded}</span>
                                </div>
                            </div>}
                            {serviceInfo.turnaroundTime && <div className="row quoteRow">
                                <div className="item-attribute">
                                    <span>Timeframe Needed by:</span>
                                    <span>{serviceInfo.turnaroundTime}</span>
                                </div>
                            </div>}
                            {modelNumber && <div className="row quoteRow">
                                <div className="item-attribute">
                                    <span>Model Number:</span>
                                    <span>{modelNumber}</span>
                                </div>
                            </div>}
                            {serviceInfo.serviceTechnicianCredentials && <div className="row quoteRow">
                                <div className="item-attribute">
                                    <span>Engineering Credentials:</span>
                                    <span>{serviceInfo.serviceTechnicianCredentials}</span>
                                </div>
                            </div>}
                            {serviceInfo.locationDetail && <div className="row quoteRow">
                                <div className="item-attribute">
                                    <span>Location Details:</span>
                                    <span>{serviceInfo.locationDetail}</span>
                                </div>
                            </div>}
                        </React.Fragment>
                    )}
                </div>
                <div className="col-md-5">
                    {fields && Object.keys(fields).length &&
                    <div className="asset-information">
                        {` Asset Information `}
                        {this.renderFields(fields)}
                    </div>}
                </div>
                <div className="col-md-3" />
            </div>
            <br />
            {options.map(option => this.renderOption(option, hidePricing))}
        </div>);
    }

    renderFields(fields) {
        return Object.keys(fields || {})
            .filter(key => fields[key])
            .map((key, i) => (<div key={i}>{`${key}: ${fields[key]}`}</div>));
    }

    renderPricing(option) {
        const price = option.price;
        const uom = option.uom || 'Each';

        return (
            <div>
                <div className="row quoteRow">
                    <div className="quote-detail_cart-panel">
                        <div className="quote-detail_cart-panel_price-info">
                            <span>Your Price: </span>
                        </div>
                        <div className="quote-detail_cart-panel_price">
                            <span className="lbl-bold">{`${formatMoney(price)}`}</span>{` / ${uom}`}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderOption(option, hidePricing) {
        const {quote: {detail: {imagePath, serviceInfo, manufacturer, isServiceItem}}, hideNoEsdMessaging} = this.props;

        return (
            <div className="row quoteRow" key={option.vendorResearchId}>
                <div
                    className="col-md-12 quoteCol separator"
                    style={{paddingTop: '25px'}}
                />
                <div className="col-md-12 quoteCol">
                    <div className="col-md-2" style={{marginTop: '25px'}}>
                        <ProductImage
                            url={imagePath}
                            style={{width: '100px'}}
                        />
                    </div>

                    <div className="col-md-4" style={{marginTop: '25px'}}>
                        <div>
                            {option.isContractProOption && (
                                <div className="row">
                                    <div className="contract-badge">
                                        <span>CONTRACT</span>
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="item-attribute">
                                    <span>
                                        {option.conditionId === 1 ? 'Manufacturer:' : 'Seller:'}
                                    </span>
                                    <span>
                                        {option.conditionId === 1
                                            ? manufacturer
                                            : 'PartsSource'}
                                    </span>
                                </div>
                            </div>

                            {!serviceInfo && (
                                <React.Fragment>
                                    <div className="row">
                                        <div className="item-attribute">
                                            <span>Condition:</span>
                                            <span>{option.conditionType}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="item-attribute">
                                            <span>Purchase Type:</span>
                                            <span>
                                                {option.purchaseChoiceId === 1
                                                    ? 'Outright Purchase'
                                                    : option.purchaseChoiceId === 2
                                                        ? 'Exchange Purchase'
                                                        : 'Repair Purchase'}
                                            </span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {!option.isContractProOption && (
                                <div className="row">
                                    <div className="item-attribute">
                                        <span>Warranty:</span>
                                        <span>{option.warranty}</span>
                                    </div>
                                </div>
                            )}
                            {!serviceInfo &&
                    !option.isContractProOption && (
                                <div className="row">
                                    <div className="item-attribute">
                                        <span>Returnable:</span>
                                        <span>{option.returnStatus}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-md-3" style={{marginTop: '25px'}}>
                        {!hidePricing && this.renderPricing(option)}
                        {!isServiceItem && 
                <div className="row quoteRow">
                    <div className="lbl-delivery">
                        <span>
                            {
                                option.purchaseChoiceId === 3 
                                    ? !option.isContractProOption && leadTimeDescriptionRepair(option.estimatedLeadTime)
                                    : !option.isContractProOption &&
                          leadTimeDescription(
                              option.estimatedShipDate,
                              null,
                              option.shipCutoffUtc,
                              hideNoEsdMessaging
                          )
                            }
                        </span>
                    </div>
                </div>}
                    </div>

                    <div className="col-md-2" style={{marginTop: '25px'}} />
                </div>
            </div>
        );
    }

    render () {
        const {onClose} = this.props;
        const {loadingPdf} = this.state;
        return (<Popup confirmText="Print"
            cancelText="Close"
            onConfirm={::this.printMe}
            show={true}
            onCancel={onClose}
            className="sales-quote-dialog"
            hideButtons={false}
            showThirdButton={true}
            onThirdButtonClick={::this.openPdf}
            thirdButtonText="Save PDF"
            thirdButtonLoading={loadingPdf}>
                       
            <div id="sales-quote" style={{width: '100%'}}>
                <img src={`${window.location.origin}/images/partssourcelogo300.png`} alt="PartsSource logo" />
                <span className="sales-order-title"><strong>Sales Order Quote</strong></span>
                {this.renderQuote()}
            </div>
        </Popup>)
    }
}
